.posts-v2__body .post-box .reply-seemore a::before, .posts-v2__body .post-box .action-row > ul > li > button:before, .posts-v2__body .post-box .account-info .desc__stick:before, .posts-v2__body .post-box .setting-more-btn:before, .posts-v2__header .post-menu-item .photos:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.posts-v2__body .post-box .mess-block .mess-details .mess-box .name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.posts-v2__header {
  margin: 0 -1.5rem 0.5rem;
  padding: 1rem 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}
.posts-v2__header .post-box {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid #e7eaec;
  align-items: center;
  flex-direction: row;
  padding-bottom: 1rem;
}
.posts-v2__header .post-box .avatar-item {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
}
.posts-v2__header .post-box .desc {
  flex-grow: 1;
  margin-left: 1rem;
}
@media (max-width: 768px) {
  .posts-v2__header .post-box .desc {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.posts-v2__header .post-box .desc .question-create {
  font-weight: 500;
  font-size: 1.25rem;
  color: #919eab;
}
.posts-v2__header .post-box .desc .question-create:hover {
  color: #0386fa;
}
.posts-v2__header .post-menu {
  padding: 1.5rem 0 1rem;
}
.posts-v2__header .post-menu-item label {
  color: #727f8e;
}
.posts-v2__header .post-menu-item .photos {
  margin-left: 2.5rem;
  font-weight: 600;
  font-size: 1rem;
  color: #0046c2;
  background: none;
}
.posts-v2__header .post-menu-item .photos:before {
  content: "\e929";
  margin-right: 0.5rem;
}
.posts-v2__header .post-menu-item .photos:hover {
  color: #0386fa;
}
.posts-v2__body {
  padding-bottom: 1.5rem;
}
.posts-v2__body .list-group {
  position: relative;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
}
.posts-v2__body .list-group-item {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
  background: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
}
.posts-v2__body .post-box {
  padding: 1rem 1rem 1.5rem;
}
.posts-v2__body .post-box .setting-more {
  position: relative;
}
.posts-v2__body .post-box .setting-more-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.posts-v2__body .post-box .setting-more-btn:before {
  content: "\e92d";
  font-size: 1.25rem;
  color: #727f8e;
}
.posts-v2__body .post-box .setting-more-btn:hover {
  background-color: #e9eeff;
  border-radius: 0.5rem;
}
.posts-v2__body .post-box .setting-more-btn:hover:before {
  color: #002f8b;
}
.posts-v2__body .post-box .setting-more-list {
  display: none;
  z-index: 6;
  position: absolute;
  top: 3rem;
  right: 0;
  width: 160px;
  background: #fff;
  width: 240px;
  border-radius: 0.25rem;
  border: 1px solid #dbdfe2;
}
.posts-v2__body .post-box .setting-more-list:before {
  position: absolute;
  top: -8px;
  right: 16px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #f7f7f7 transparent;
}
.posts-v2__body .post-box .setting-more-list > li {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  line-height: 18px;
  padding: 1rem;
  font-size: 1rem;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  color: #314154;
}
.posts-v2__body .post-box .setting-more-list > li:hover {
  color: #de3618 !important;
  background-color: #f4f6f8;
}
.posts-v2__body .post-box .setting-more-list > li:not(:first-child) {
  border-top: 1px dashed rgba(151, 151, 151, 0.2);
}
.posts-v2__body .post-box .setting-more.uiselector.open .setting-more-btn {
  background-color: #e9eeff;
  border-radius: 0.5rem;
}
.posts-v2__body .post-box .setting-more.uiselector.open .setting-more-btn:before {
  color: #002f8b;
}
.posts-v2__body .post-box .setting-more.uiselector.open .setting-more-list {
  display: block;
}
.posts-v2__body .post-box .account {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1rem;
}
.posts-v2__body .post-box .account .avatar-item {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
}
.posts-v2__body .post-box .account-info {
  flex-grow: 1;
  margin-left: 0.5rem;
  align-items: center;
}
@media (max-width: 768px) {
  .posts-v2__body .post-box .account-info {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.posts-v2__body .post-box .account-info .name {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #314154;
}
.posts-v2__body .post-box .account-info .desc {
  display: flex;
  align-items: center;
}
.posts-v2__body .post-box .account-info .desc__tag {
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0.25em 0.625em;
  background-color: #e9eeff;
  color: #3e64ff;
  height: 20px;
}
.posts-v2__body .post-box .account-info .desc__date {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #727f8e;
  margin-left: 0.5rem;
}
.posts-v2__body .post-box .account-info .desc .dot {
  margin-left: 0.5rem;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #919eab;
}
.posts-v2__body .post-box .account-info .desc__stick {
  background: none;
  margin-left: 1rem;
}
.posts-v2__body .post-box .account-info .desc__stick:before {
  content: "\e91c";
  color: #727f8e;
  font-size: 1rem;
}
.posts-v2__body .post-box .title {
  font-size: 1rem;
  font-weight: 600;
  color: #314154;
  margin-bottom: 1rem;
}
.posts-v2__body .post-box .details {
  margin: 1rem 0;
}
.posts-v2__body .post-box .details p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #314154;
  margin-bottom: 1rem;
}
.posts-v2__body .post-box .details img {
  display: block;
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.5rem;
}
@media (min-width: 768px) {
  .posts-v2__body .post-box .details img {
    height: 440px;
  }
}
.posts-v2__body .post-box .account-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
.posts-v2__body .post-box .account-group .avatar {
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
}
.posts-v2__body .post-box .account-group .avatar-group {
  display: inline-flex;
  flex-direction: row-reverse;
  margin-right: 0.5rem;
}
.posts-v2__body .post-box .account-group .avatar:not(:last-child) {
  margin-left: -8px;
}
.posts-v2__body .post-box .account-group .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.posts-v2__body .post-box .account-group .txt {
  font-size: 1rem;
  color: #727f8e;
}
.posts-v2__body .post-box .action-row {
  border-top: 1px solid #e7eaec;
}
.posts-v2__body .post-box .action-row > ul {
  display: flex;
}
.posts-v2__body .post-box .action-row > ul > li {
  width: 33.333%;
  height: 32px;
  padding: 4px 0;
}
.posts-v2__body .post-box .action-row > ul > li > button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  height: 24px;
  border-radius: 4px;
}
.posts-v2__body .post-box .action-row > ul > li > button.active:before {
  background-image: url(../../images/icons/like_active.svg);
  background-size: 100% 100%;
  content: "";
  width: 15px;
  height: 15px;
}
@media (min-width: 871px) {
  .posts-v2__body .post-box .action-row > ul > li > button:hover {
    background: rgba(216, 216, 216, 0.2);
  }
}
.posts-v2__body .post-box .action-row > ul > li > button {
  color: #979797;
}
.posts-v2__body .post-box .action-row > ul > li > button .num {
  margin-left: 6px;
  padding-top: 3px;
}
.posts-v2__body .post-box .action-row > ul > li .like {
  width: calc(100% - 2px);
  margin-right: 2px;
}
.posts-v2__body .post-box .action-row > ul > li .like::before {
  content: "\e918";
  font-size: 15px;
}
.posts-v2__body .post-box .action-row > ul > li .mess {
  width: calc(100% - 4px);
  margin: 0 2px;
}
.posts-v2__body .post-box .action-row > ul > li .mess:before {
  content: "\e919";
  font-size: 15px;
}
.posts-v2__body .post-box .action-row > ul > li .share {
  width: calc(100% - 2px);
  margin-left: 2px;
}
.posts-v2__body .post-box .action-row > ul > li .share:before {
  content: "\e91d";
  font-size: 14px;
}
.posts-v2__body .post-box .action-row + .mess-block {
  padding-top: 16px;
  border-top: 1px solid rgba(151, 151, 151, 0.1);
}
.posts-v2__body .post-box .action-row + .mess-seemore, .posts-v2__body .post-box .action-row + .reply-seemore {
  display: block;
  position: relative;
  margin-top: 12px;
}
.posts-v2__body .post-box .action-row + .mess-seemore:before, .posts-v2__body .post-box .action-row + .reply-seemore:before {
  background: #e7eaec;
  position: absolute;
  top: -12px;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
}
.posts-v2__body .post-box .mess-seemore, .posts-v2__body .post-box .reply-seemore {
  margin-bottom: 1rem;
}
.posts-v2__body .post-box .mess-seemore a, .posts-v2__body .post-box .reply-seemore a {
  display: inline-block;
  line-height: 1.25rem;
  color: #444;
  font-size: 0.875rem;
  font-weight: 500;
}
@media (min-width: 1051px) {
  .posts-v2__body .post-box .mess-seemore a:hover, .posts-v2__body .post-box .reply-seemore a:hover {
    text-decoration: underline;
  }
}
.posts-v2__body .post-box .reply-seemore a {
  position: relative;
  padding-left: 18px;
}
.posts-v2__body .post-box .reply-seemore a::before {
  position: absolute;
  top: 1px;
  left: 0;
  content: "\e942";
  font-size: 13px;
}
.posts-v2__body .post-box .mess-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 16px;
}
.posts-v2__body .post-box .mess-block .avatar {
  margin-right: 8px;
}
.posts-v2__body .post-box .mess-block .avatar > img {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.posts-v2__body .post-box .mess-block .mess-details {
  width: calc(100% - 40px);
}
.posts-v2__body .post-box .mess-block .mess-details .mess-box {
  background: #f4f6f8;
  padding: 12px 5px 10px 12px;
  border-radius: 0.5rem;
}
.posts-v2__body .post-box .mess-block .mess-details .mess-box .name, .posts-v2__body .post-box .mess-block .mess-details .mess-box .mess {
  color: #314154;
  line-height: 1.25rem;
  font-size: 0.875rem;
}
.posts-v2__body .post-box .mess-block .mess-details .mess-box .name {
  font-weight: 600;
}
.posts-v2__body .post-box .mess-block .mess-details .mess-box .mess {
  margin-top: 0.25rem;
}
.posts-v2__body .post-box .mess-block .mess-details .d-row {
  display: flex;
  margin-top: 0.5rem;
}
.posts-v2__body .post-box .mess-block .mess-details .d-row + .reply-seemore {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.posts-v2__body .post-box .mess-block .mess-details .d-row .date {
  line-height: 1.25rem;
  color: #727f8e;
  font-size: 0.75rem;
  font-weight: 500;
}
.posts-v2__body .post-box .mess-block .mess-details .d-row .answer {
  line-height: 1.25rem;
  color: #727f8e;
  font-size: 0.75rem;
  font-weight: 600;
  background: none;
  width: 44px;
  height: 18px;
}
@media (min-width: 871px) {
  .posts-v2__body .post-box .mess-block .mess-details .d-row .answer:hover {
    text-decoration: underline;
  }
}
.posts-v2__body .post-box .mess-block > .mess-block {
  padding: 1rem 0 0 2.5rem;
}
.posts-v2__body .post-box .mess-block > .mess-block > .avatar > img {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}
.posts-v2__body .post-box .mess-block > .mess-block > .mess-details {
  width: calc(100% - 32px);
}
.posts-v2__body .post-box .mess-block > .mess-block > .mess-frm {
  width: 100%;
  margin: 12px 0 0;
}
.posts-v2__body .post-box .mess-block > .mess-block > .mess-frm .avatar {
  padding-top: 4px;
}
.posts-v2__body .post-box .mess-block > .mess-block > .mess-frm .avatar > img {
  width: 24px;
  height: 24px;
}
.posts-v2__body .post-box .mess-frm {
  display: flex;
  margin-bottom: 1rem;
}
.posts-v2__body .post-box .mess-frm .avatar {
  margin-right: 0.5rem;
}
.posts-v2__body .post-box .mess-frm .avatar > img {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.posts-v2__body .post-box .mess-frm .txt-frm {
  flex-grow: 1;
}
.posts-v2__body .post-box .mess-frm .txt-frm > input {
  background: #f4f6f8;
  line-height: 18px;
  width: 100%;
  height: 32px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 7px;
  border: 0;
}
.posts-v2__body .post-box__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 1.5rem;
}
.posts-v2__body .post-box__empty .thumbnail-item {
  width: 175px;
  height: 186px;
}
.posts-v2__body .post-box__empty .title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #314154;
  margin: 1rem 0;
}
.posts-v2__body .post-box__empty .txt {
  font-size: 1rem;
  color: #727f8e;
  margin-bottom: 1rem;
}

/*# sourceMappingURL=posts_v2.css.map */
