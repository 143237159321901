.right-bar-header .btn-close:before, .users-event-box .action-share:before, .users-event-box .card-body .type.triathlon:before, .users-event-box .card-body .type.swim:before, .users-event-box .card-body .type.bike:before, .users-event-box .card-body .type.run:before, .users-event-box .card-body .privacy.public:before, .users-event-box .card-body .privacy.private:before, .users-event-box .card-body .calendar:before, .users-event-box .card .official:before, .users-event-box .card .btn-close:before, .users-event-box-header .view-all::after, .users-event-search .filter button:before, .users-event-search .keyword:before, .users-event--tabs-menu .right .btn-40.btn-icon-add .txt::before, .users-event--tabs-menu > ul > li a:before, .users-event-page .btn-add:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.users-event-page {
  background-color: #f9fafb;
  padding: 64px 70px;
}
@media (max-width: 1050px) {
  .users-event-page {
    padding: 50px 16px;
  }
}
.users-event-page .carousel .slide {
  background: none;
}
@media (max-width: 767px) {
  .users-event-page .btn-add {
    width: 100%;
  }
}
.users-event-page .btn-add:before {
  content: "\e100";
  margin-right: 5px;
}
.users-event-wrap {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 1200px) {
  .users-event-wrap {
    width: 1110px;
  }
}
.users-event-wrap .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.users-event-wrap .row > * {
  flex-shrink: 0;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.users-event-info {
  margin-top: 16px;
}
@media (max-width: 650px) {
  .users-event-info {
    margin-top: 10px;
  }
}
.users-event--banner {
  display: flex;
  align-items: center;
  flex-flow: column;
}
.users-event--banner img {
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: 8px;
}
@media (max-width: 1050px) {
  .users-event--banner img {
    height: 240px;
  }
}
.users-event--tabs-menu {
  display: flex;
  margin-top: 1.5rem;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
}
@media (max-width: 1050px) {
  .users-event--tabs-menu {
    position: relative;
    padding-top: 72px;
  }
}
.users-event--tabs-menu > ul {
  flex-grow: 1;
  display: flex;
}
@media (max-width: 1050px) {
  .users-event--tabs-menu > ul > li {
    width: 50%;
  }
}
.users-event--tabs-menu > ul > li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  line-height: 30px;
  padding-right: 40px;
  padding-left: 40px;
  color: #314154;
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 1050px) {
  .users-event--tabs-menu > ul > li a {
    width: 100%;
    height: 48px;
    line-height: 24px;
    padding: 0 0 0 22px;
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .users-event--tabs-menu > ul > li a {
    font-size: 14px;
  }
}
.users-event--tabs-menu > ul > li a:before {
  position: absolute;
  color: rgba(68, 68, 68, 0.8);
  transition: all 0.3s ease 0s;
}
@media (min-width: 1051px) {
  .users-event--tabs-menu > ul > li a:hover {
    color: #0386fa;
  }
}
@media (min-width: 1051px) {
  .users-event--tabs-menu > ul > li a:hover::before {
    color: #0386fa;
    transform: scale(1.1, 1.1);
  }
}
.users-event--tabs-menu > ul > li.active a {
  color: #0046c2;
  font-weight: 600;
  cursor: default;
}
.users-event--tabs-menu > ul > li.active a::before {
  color: #0046c2;
  transform: scale(1, 1);
}
.users-event--tabs-menu > ul > li.active a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: #0046c2;
  width: 100%;
  height: 2px;
  border-radius: 2px;
}
.users-event--tabs-menu .right {
  display: flex;
  padding-top: 5px;
}
@media (max-width: 1050px) {
  .users-event--tabs-menu .right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.users-event--tabs-menu .right .btn-40 {
  width: 100%;
  font-size: 16px;
}
.users-event--tabs-menu .right .btn-40.btn-icon-add.disabled, .users-event--tabs-menu .right .btn-40.btn-icon-add:disabled {
  border: none !important;
}
.users-event--tabs-menu .right .btn-40.btn-icon-add.disabled .txt, .users-event--tabs-menu .right .btn-40.btn-icon-add:disabled .txt {
  color: #919eab !important;
}
.users-event--tabs-menu .right .btn-40.btn-icon-add.disabled .txt:before, .users-event--tabs-menu .right .btn-40.btn-icon-add:disabled .txt:before {
  color: #919eab !important;
}
.users-event--tabs-menu .right .btn-40.btn-icon-add .txt {
  position: relative;
  padding-left: 22px;
}
.users-event--tabs-menu .right .btn-40.btn-icon-add .txt::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e923";
  font-size: 18px;
}
.users-event--tabs-content {
  position: relative;
}
.users-event--tabs-content > ul > li {
  position: relative;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.users-event--tabs-content > ul > li.active {
  visibility: visible;
  height: auto;
  overflow: inherit;
}
.users-event--tabs-content .filter-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 1.5rem;
}
@media (max-width: 768px) {
  .users-event--tabs-content .filter-group {
    flex-direction: column;
  }
}
.users-event--tabs-content .filter-group-item:not(:last-child) {
  margin-right: 1rem;
}
@media (max-width: 768px) {
  .users-event--tabs-content .filter-group-item {
    margin-top: 0.5rem;
    margin-right: 0 !important;
  }
}
.users-event--tabs-content .filter-group-item .btn-check:checked + label {
  font-weight: 600;
}
.users-event--tabs-content .filter-group-item .btn-check + label {
  display: inline-block !important;
  min-height: 40px;
  height: auto;
  word-break: break-all;
}
@media (max-width: 768px) {
  .users-event--tabs-content .filter-group-item .btn-check + label {
    width: 100%;
  }
}
.users-event--tabs-content .filter-group-item .btn-outline-secondary {
  background: transparent !important;
}
.users-event-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .users-event-search {
    flex-direction: column;
  }
}
.users-event-search .keyword {
  position: relative;
  height: 40px;
}
.users-event-search .keyword:before {
  position: absolute;
  left: 15px;
  top: calc(50% - 10px + 0px);
  font-size: 20px;
  content: "\e91f";
  color: #727f8e;
}
.users-event-search .keyword > input {
  background: #f9fafb;
  width: 100%;
  height: 100%;
  padding: 15px 45px 15px 45px;
  color: #000;
  font-size: 16px;
  border: 1px solid #dbdfe2;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .users-event-search .keyword > input {
    min-width: 400px;
  }
}
@media (max-width: 767px) {
  .users-event-search .filter {
    margin-top: 1rem;
    width: 100%;
  }
}
.users-event-search .filter button {
  background-color: #f9fafb;
  border: 1px solid #dbdfe2;
  color: #314154;
  font-weight: normal;
  font-size: 16px;
  min-width: 110px;
  padding: 0 1rem 0 3rem;
}
.users-event-search .filter button:before {
  position: absolute;
  top: calc(50% - 10px);
  left: 1rem;
  font-size: 20px;
  content: "\e905";
  color: #727f8e;
  margin-right: 5px;
}
.users-event-search .filter.notify button:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 28px;
  width: 10px;
  height: 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #0046c2;
}
.users-event-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.users-event-box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.users-event-box-header .title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #212b36;
}
.users-event-box-header .view-all {
  font-size: 1rem;
  font-weight: 600;
  color: #0046c2;
}
.users-event-box-header .view-all::after {
  content: "\e907";
  font-size: 0.625rem;
  margin-left: 0.5rem;
}
.users-event-box .card {
  height: 100%;
}
.users-event-box .card .event-thumbnail {
  width: 100%;
  object-fit: cover;
  height: 135px;
}
.users-event-box .card .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background-color: rgba(51, 51, 51, 0.8);
  border-radius: 50%;
  cursor: pointer;
}
.users-event-box .card .btn-close:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "\e90b";
  font-size: 0.625rem;
  color: #fff;
}
.users-event-box .card .official {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 18px;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #3e64ff;
  border-radius: 0.25rem;
  padding: 0 0.5rem 0 1rem;
  font-size: 0.625rem;
  font-weight: 600;
}
.users-event-box .card .official:before {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  left: -5px;
  content: "\e909";
  font-size: 8px;
  color: #fff;
  background: #3e64ff;
  border: 2px solid #fff;
  width: 19px;
  height: 19px;
  border-radius: 50%;
}
.users-event-box .card-body {
  padding: 1rem 1rem 0;
}
.users-event-box .card-body .calendar {
  color: #727f8e;
}
.users-event-box .card-body .calendar:before {
  position: absolute;
  content: "\e102";
  font-size: 1.25rem;
}
.users-event-box .card-body .calendar .txt {
  font-size: 0.875rem;
  font-weight: 600;
  padding-left: 1.5rem;
}
.users-event-box .card-body .title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #212b36;
  margin: 0.5rem 0;
}
.users-event-box .card-body .description {
  display: flex;
  margin: 0.75rem 0;
  flex-direction: row;
  align-items: center;
}
.users-event-box .card-body .badge {
  color: #22cbc1;
  border: 1px solid #24cdc3;
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.25em 0.625em;
  margin-right: 0.5rem;
}
.users-event-box .card-body .privacy {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #727f8e;
}
.users-event-box .card-body .privacy.private:before {
  content: "\e104";
}
.users-event-box .card-body .privacy.public:before {
  content: "\e105";
}
.users-event-box .card-body .avatar {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 24px;
  height: 24px;
}
.users-event-box .card-body .avatar-group {
  display: inline-flex;
  flex-direction: row-reverse;
  margin-right: 0.5rem;
}
.users-event-box .card-body .avatar:not(:last-child) {
  margin-left: -8px;
}
.users-event-box .card-body .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.users-event-box .card-body .type.run:before {
  content: "\e91e";
  color: #727f8e;
  font-size: 20px;
  margin-right: 0.5rem;
}
.users-event-box .card-body .type.bike:before {
  content: "\e939";
  color: #727f8e;
  font-size: 20px;
  margin-right: 0.5rem;
}
.users-event-box .card-body .type.swim:before {
  content: "\e938";
  color: #727f8e;
  font-size: 20px;
  margin-right: 0.5rem;
}
.users-event-box .card-body .type.triathlon:before {
  content: "\e937";
  color: #727f8e;
  font-size: 20px;
  margin-right: 0.5rem;
}
.users-event-box .action {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.users-event-box .action-share {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin-left: 0.75rem;
}
.users-event-box .action-share:before {
  content: "\e91d";
}
.users-event-box .action-join {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.users-event-box.box--empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem !important;
}
.users-event-box.box--empty .thumbnail-item {
  width: 320px;
  height: 320px;
}
@media (max-width: 768px) {
  .users-event-box.box--empty .thumbnail-item {
    width: 240px;
    height: 240px;
  }
}
.users-event-box.box--empty .thumbnail-item__list-search {
  width: 180px;
  height: 200px;
}
@media (max-width: 768px) {
  .users-event-box.box--empty .thumbnail-item__list-search {
    width: 130px;
    height: 150px;
  }
}
.users-event-box.box--empty .txt {
  margin: 1rem 0;
  font-size: 1rem;
  color: #314154;
}
.users-event-box.box--empty .txt-title {
  margin: 0.5rem 0;
  font-weight: 600;
  font-size: 1.125rem;
  color: #314154;
}

.right-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 395px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  bottom: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
.right-bar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid #e7eaec;
}
.right-bar-header-title {
  color: #212b36;
  font-size: 1.25rem;
  font-weight: 600;
}
.right-bar-header .btn-close {
  background: transparent;
}
.right-bar-header .btn-close:before {
  content: "\e90b";
  color: #727f8e;
}
.right-bar-body {
  height: 100% !important;
  overflow-y: auto;
}
.right-bar .filter-group-item {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}
.right-bar .filter-group-item:not(:last-child) {
  border-bottom: 1px solid #e7eaec;
}
.right-bar .filter-group-item-title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #212b36;
  padding-left: 0.5rem;
}
.right-bar .filter-group-item-body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.right-bar .filter-group-item-body .col {
  flex: 0 0 auto;
  width: 50%;
  margin-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.right-bar .filter-group-item-body .btn-check + label {
  display: inline-block !important;
  width: 100%;
  min-height: 35px;
  height: auto;
  word-break: break-all;
}
.right-bar-footer {
  border-top: 1px solid #e7eaec;
}
.right-bar-footer .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 1.25rem;
}
@media (min-width: 568px) {
  .right-bar-footer .action {
    flex-direction: row !important;
  }
}
.right-bar-footer .action-apply {
  padding: 0.5rem 2rem;
  margin-left: 0.75rem;
}
@media (max-width: 567px) {
  .right-bar-footer .action-apply {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0 !important;
  }
}
.right-bar-footer .action-cancel {
  padding: 0.5rem 2rem;
}
@media (max-width: 567px) {
  .right-bar-footer .action-cancel {
    width: 100%;
  }
}

.offcanvas.show {
  visibility: visible !important;
  transform: none !important;
}

.offcanvas-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9990;
  width: 100%;
  height: 100%;
  background-color: #212b36;
  opacity: 0.5;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  display: none;
}

.offcanvas-backdrop.show {
  display: block !important;
}

body.lock-scrollbar {
  overflow: hidden;
}

.email-verification-page {
  background-color: #f9fafb;
  padding: 64px 70px;
}
@media (max-width: 1050px) {
  .email-verification-page {
    padding: 50px 16px;
  }
}
.email-verification-wrap {
  width: 100%;
  margin: 0 auto;
  padding-top: 3rem;
}
@media (min-width: 1200px) {
  .email-verification-wrap {
    width: 1110px;
  }
}
.email-verification-wrap .avatar {
  position: relative;
  display: inline-block;
  width: 5rem;
  height: 5rem;
}
@media (min-width: 768px) {
  .email-verification-wrap .avatar {
    width: 8rem;
    height: 8rem;
  }
}
.email-verification-wrap h1, .email-verification-wrap h2, .email-verification-wrap h4 {
  font-weight: 600;
  color: #000;
  line-height: 1.2;
}
.email-verification-wrap .display-5 {
  font-size: 1.5rem;
}
.email-verification-wrap p {
  font-size: 1rem;
  color: #314154;
  line-height: 1.5;
}
.email-verification-wrap ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-left: 1rem;
}
.email-verification-wrap ol ::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0 !important;
  text-align: start !important;
  text-align-last: start !important;
}
.email-verification-wrap ol li {
  display: list-item;
  text-align: -webkit-match-parent;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #314154;
}
.email-verification-wrap ol li :not(:last-child) {
  padding-bottom: 0.5rem;
}
.email-verification-wrap .card-body {
  padding: 1rem;
}
@media (min-width: 768px) {
  .email-verification-wrap .card-body {
    padding: 2rem;
  }
}
.email-verification-wrap .form-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.email-verification-wrap .form-control {
  min-height: calc(1.5em + 1rem);
}

.event-detail-page .card-details .empty-box {
  border: 0;
}

/*# sourceMappingURL=users_event.css.map */
