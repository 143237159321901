@font-face {
  font-family: "uprace";
  src: url("../../fonts/uprace.eot");
  src: url("../../fonts/uprace.eot?#iefix") format("embedded-opentype"), url("../../fonts/uprace.woff") format("woff"), url("../../fonts/uprace.ttf") format("truetype"), url("../../fonts/uprace.svg#uprace") format("svg");
  font-weight: normal;
  font-style: normal;
}
input[type=checkbox] + label span.checkbox:before, .icon-group_checked:before, .icon-notify:before, .icon-triangle:before, .icon-virtual_race:before, .icon-check_thick:before, .icon-user:before, .icon-group:before, .btn-preview:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  width: 100% !important;
  height: auto !important;
  overflow: auto;
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px !important;
}

@media (max-width: 1050px) {
  ::-webkit-scrollbar {
    width: 4px !important;
  }
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff9100 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e45705 !important;
}

dl, ol, ul {
  margin: 0;
}

.ant-picker-time-panel-column:after {
  height: auto !important;
}

.section--footer .col {
  flex: none;
}

.profile-page .profile-team-list > li .logo img {
  height: 100% !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

input[type=checkbox] + label span.checkbox:before, .icon-group_checked:before, .icon-notify:before, .icon-triangle:before, .icon-virtual_race:before, .icon-check_thick:before, .icon-user:before, .icon-group:before, .btn-preview:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }
}
.text-ink-light {
  color: #314154 !important;
}

.text-ink-lighter {
  color: #727f8e !important;
}

.text-blue {
  color: #0046c2 !important;
}

.text-red {
  color: #de3618 !important;
}

.fw-normal {
  font-weight: normal;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 768px) {
  .col-tablet {
    flex: 1 0 0%;
  }

  .row-cols-tablet-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-tablet-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-tablet-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-tablet-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-tablet-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-tablet-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-tablet-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-tablet-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-tablet-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-tablet-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-tablet-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-tablet-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-tablet-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-tablet-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-tablet-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-tablet-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-tablet-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-tablet-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-tablet-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-tablet-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1180px) {
  .col-desktop {
    flex: 1 0 0%;
  }

  .row-cols-desktop-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-desktop-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-desktop-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-desktop-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-desktop-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-desktop-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-desktop-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-desktop-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-desktop-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-desktop-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-desktop-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-desktop-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-desktop-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-desktop-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-desktop-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-desktop-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-desktop-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-desktop-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-desktop-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-desktop-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
.g-0, .gx-0 {
  --bs-gutter-x: 0 ;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0 ;
}

.g-1, .gx-1 {
  --bs-gutter-x: 0.25rem ;
}

.g-1, .gy-1 {
  --bs-gutter-y: 0.25rem ;
}

.g-2, .gx-2 {
  --bs-gutter-x: 0.5rem ;
}

.g-2, .gy-2 {
  --bs-gutter-y: 0.5rem ;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem ;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem ;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem ;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem ;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem ;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem ;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.ms-auto {
  margin-left: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-auto {
  margin-top: auto !important;
}

.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.m-auto {
  margin: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5rem;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn.inactive {
  pointer-events: none;
  color: #919eab;
  background-color: #f4f6f8;
  border: 1px solid #dbdfe2;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-blue {
  color: #fff;
  background-color: #0046c2;
  border: 1px solid #0046c2;
}
.btn-blue.inactive {
  pointer-events: none;
  color: #919eab;
  background-color: #f4f6f8;
  border: 1px solid #dbdfe2;
}
.btn-blue:hover {
  color: #fff;
  background-color: #3e64ff;
  border: 1px solid #3e64ff;
}
.btn-red {
  color: #fff;
  background-color: #de3618;
  border: 1px solid #de3618;
}
.btn-red.inactive {
  pointer-events: none;
  color: #919eab;
  background-color: #f4f6f8;
  border: 1px solid #dbdfe2;
}
.btn-red:hover {
  color: #fff;
  background-color: #b21907;
  border: 1px solid #b21907;
}
.btn-outline-blue {
  color: #0046c2;
  background-color: #fff;
  border: 1px solid #0046c2;
}
.btn-outline-blue:hover {
  color: #0046c2;
  background-color: #e9eeff;
  border: 1px solid #0046c2;
}
.btn-outline-blue.inactive {
  pointer-events: none;
  color: #919eab;
  background-color: #f4f6f8;
  border: 1px solid #dbdfe2;
}
.btn-outline-secondary {
  color: #314154;
  background-color: #fff;
  border: 1px solid #dbdfe2;
}
.btn-outline-secondary.inactive {
  pointer-events: none;
  color: #919eab;
  background-color: #f4f6f8;
  border: 1px solid #dbdfe2;
}
.btn-outline-secondary:hover {
  color: #0046c2;
  background-color: #fff;
  border: 1px solid #0046c2;
}
.btn-blue-light {
  color: #0046c2;
  background-color: #e9eeff;
  border: 1px solid #e9eeff;
}
.btn-blue-light:hover {
  color: #fff;
  background-color: #3e64ff;
  border: 1px solid #3e64ff;
}
.btn-blue-light.inactive {
  pointer-events: none;
  color: #919eab;
  background-color: #f4f6f8;
  border: 1px solid #dbdfe2;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border: 1px solid #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #444;
  border: 1px solid #444;
}
.btn-secondary.inactive {
  pointer-events: none;
  color: #919eab;
  background-color: #f4f6f8;
  border: 1px solid #dbdfe2;
}
.btn-primary {
  color: #fff;
  background-color: #0386fa;
  border: 1px solid #0386fa;
}
.btn-primary:hover {
  color: #fff;
  background-color: #3e64ff;
  border: 1px solid #3e64ff;
}
.btn-primary.inactive {
  pointer-events: none;
  color: #919eab;
  background-color: #f4f6f8;
  border: 1px solid #dbdfe2;
}

.btn-check:disabled + .btn, .btn-check[disabled] + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.btn.disabled, .btn:disabled, .btn-blue.disabled, .btn-blue:disabled, .btn-outline-blue.disabled,
.btn-outline-blue:disabled, .btn-red.disabled, .btn-red:disabled, .btn-outline-secondary:disabled,
.btn-outline-secondary.disabled, .btn-blue-light.disabled, .btn-blue-light:disabled {
  pointer-events: none;
  color: #919eab;
  background-color: #f4f6f8;
  border: 1px solid #dbdfe2;
}

.btn-check:checked + .btn-outline-secondary {
  color: #0046c2;
  background-color: #fff;
  border-color: #0046c2;
}

.btn-check:disabled + .btn, .btn-check[disabled] + .btn {
  pointer-events: none;
  filter: none;
}

.btn-preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-preview:before {
  content: "\e911";
  font-size: inherit;
  margin-right: 0.5rem;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  list-style: none;
  color: #212b36;
  margin: 2rem 0;
  font-weight: 600;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #212b36;
  content: "/";
}
.breadcrumb-item.active {
  color: #919eab;
  font-weight: normal !important;
}
.breadcrumb-item a {
  color: #212b36;
}
.breadcrumb-item a:hover {
  color: #0386fa;
}

.icon-group {
  position: relative;
}
.icon-group:before {
  content: "\e933";
  font-size: inherit;
}
.icon-user {
  position: relative;
}
.icon-user:before {
  content: "\e92c";
  font-size: inherit;
}
.icon-check_thick {
  position: relative;
}
.icon-check_thick:before {
  content: "\e909";
  font-size: inherit;
}
.icon-virtual_race {
  position: relative;
}
.icon-virtual_race:before {
  content: "\e107";
  font-size: inherit;
}
.icon-triangle {
  position: relative;
}
.icon-triangle:before {
  content: "\e106";
  font-size: inherit;
}
.icon-notify {
  position: relative;
}
.icon-notify:before {
  content: "\e91a";
  font-size: inherit;
}
.icon-group_checked {
  position: relative;
}
.icon-group_checked:before {
  content: "\e90e";
  font-size: inherit;
}

.rounded {
  border-radius: 0.25rem !important;
}

.border-0 {
  border: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
}
.badge:empty {
  display: none;
}

.bg-red {
  background-color: #de3618 !important;
}

.text-center {
  text-align: center !important;
}

.h-50 {
  height: 50% !important;
}

.frm-checkbox {
  display: flex;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox] + label {
  display: flex;
  height: 22px;
}
input[type=checkbox] + label span {
  display: block;
  position: relative;
  height: 22px;
}
input[type=checkbox] + label span.checkbox {
  width: 22px;
  margin-right: 8px;
}
input[type=checkbox] + label span.checkbox:before {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 11px);
  content: "\e914";
  color: #797979;
  font-size: 22px;
}
input[type=checkbox] + label span.txt {
  flex-grow: 1;
  line-height: 24px;
  font-size: 16px;
}

input[type=checkbox]:checked + label span.checkbox:before {
  content: "\e915";
  color: #0046c2;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212b36;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdfe2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.is-invalid {
  border: 1px solid #de3618 !important;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  font-weight: 500;
  color: #de3618;
}

.tabs-v2-menu {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
}
.tabs-v2-menu .left {
  display: flex;
  align-items: center;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .tabs-v2-menu .left {
    margin-right: unset !important;
  }
}
.tabs-v2-menu.tab-pills .left .menu-link, .tabs-v2-menu .left .menu-link {
  text-decoration: none;
  color: #314154;
  padding: 1.5rem;
  font-weight: 500;
  font-size: 1.25rem;
}
@media (max-width: 1024px) {
  .tabs-v2-menu.tab-pills .left .menu-link, .tabs-v2-menu .left .menu-link {
    font-size: 0.875rem;
    padding: 1rem 0.75rem;
  }
}
.tabs-v2-menu .left .menu-item:hover .menu-link:not(.active) {
  color: #0386fa;
}
.tabs-v2-menu .left .menu-item:hover .menu-link:not(.active):before {
  color: #0386fa;
}
.tabs-v2-menu .left .menu-link {
  display: block;
}
.tabs-v2-menu .left .menu-link.active {
  color: #0046c2;
  font-weight: 600;
  border-bottom: 2px solid #0046c2;
}
.tabs-v2-menu .left .menu-link.active:before {
  color: #0046c2;
}
.tabs-v2-menu.tab-pills {
  border-bottom: 0;
}
.tabs-v2-menu.tab-pills .left {
  display: flex;
  align-items: center;
  margin-right: auto;
  background: #f4f6f8;
  border: 1px solid #e7eaec;
  border-radius: 0.5rem;
}
@media (max-width: 1024px) {
  .tabs-v2-menu.tab-pills .left {
    margin-right: 0;
  }
}
.tabs-v2-menu.tab-pills .left .menu-link {
  text-decoration: none;
  color: #314154;
  font-weight: 500;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .tabs-v2-menu.tab-pills .left .menu-link {
    font-size: 1.125rem;
  }
}
.tabs-v2-menu.tab-pills .left .menu-item {
  padding: 0.25rem;
  text-align: center;
}
.tabs-v2-menu.tab-pills .left .menu-item:hover .menu-link:not(.active) {
  color: #0386fa;
}
.tabs-v2-menu.tab-pills .left .menu-item:hover .menu-link:not(.active):before {
  color: #0386fa;
}
.tabs-v2-menu.tab-pills .left .menu-link {
  display: block;
}
.tabs-v2-menu.tab-pills .left .menu-link.active {
  color: #fff;
  font-weight: 600;
  background: #0046c2;
  border-radius: 0.5rem;
}
.tabs-v2-content > ul > li {
  display: none;
}
.tabs-v2-content > ul > li.active {
  display: block;
}

/*# sourceMappingURL=uprace.css.map */
