.group-detail-info .group-info__introduce .desc .see-more.active:before, .group-detail-info .group-info__introduce .desc .see-more:before, .group-detail-info .group-info-row .btn-group .setting-more-btn:before, .group-detail-info .group-info__status a:after, .group-detail-info .group-info__owner:before, .group-detail-info .group-info__details .l-col .ico.flags:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.group-detail-wrap {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 2rem;
}
.group-detail-wrap .card-details.card-rank {
  margin: 0 0.5rem;
}
@media (min-width: 768px) {
  .group-detail-wrap .card-details.card-rank {
    margin: 0 4rem;
  }
}
@media (min-width: 960px) {
  .group-detail-wrap .card-details.card-rank {
    margin: 0 10rem;
  }
}
@media (min-width: 1200px) {
  .group-detail-wrap .card-details.card-rank {
    margin: 0 calc(50% - 475px);
  }
}
.group-detail-wrap .manage-wrap {
  padding: 0 0.5rem 1rem;
}
@media (min-width: 768px) {
  .group-detail-wrap .manage-wrap {
    padding: 0 4rem 1rem;
  }
}
@media (min-width: 960px) {
  .group-detail-wrap .manage-wrap {
    padding: 0 10rem 1rem;
  }
}
@media (min-width: 1400px) {
  .group-detail-wrap .manage-wrap {
    padding: 0 calc(50% - 475px) 1rem;
  }
}
.group-detail-info {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  margin-bottom: 1.5rem;
  padding: 0 0.5rem 1rem;
}
@media (min-width: 768px) {
  .group-detail-info {
    padding: 0 4rem 1rem;
  }
}
@media (min-width: 960px) {
  .group-detail-info {
    padding: 0 10rem 1rem;
  }
}
@media (min-width: 1400px) {
  .group-detail-info {
    padding: 0 calc(50% - 475px) 1rem;
  }
}
.group-detail-info .group-info__avatar {
  position: relative;
}
.group-detail-info .group-info__avatar .avatar-item {
  position: absolute;
  left: 1.5rem;
  top: -3.5rem;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: linear-gradient(355.87deg, #E9E9E9 -31.62%, rgba(255, 255, 255, 0) 63.12%);
}
@media (min-width: 768px) {
  .group-detail-info .group-info__avatar .avatar-item {
    width: 112px;
    height: 112px;
  }
}
.group-detail-info .group-info__details {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e7eaec;
  margin-bottom: 1.25rem;
  padding-top: 35px;
}
@media (min-width: 992px) {
  .group-detail-info .group-info__details {
    flex-direction: row;
    padding-top: 0;
    padding-left: 150px;
  }
}
.group-detail-info .group-info__details .l-col .ico.flags {
  text-align: center;
}
@media (min-width: 992px) {
  .group-detail-info .group-info__details .l-col .ico.flags {
    display: inline-flex;
    align-items: center;
    text-align: left;
    width: 2.5rem;
    height: 2.5rem;
  }
}
.group-detail-info .group-info__details .l-col .ico.flags:before {
  content: "\e121";
  font-size: 1.75rem;
  color: #EF8A05;
}
.group-detail-info .group-info__details .r-col {
  flex-grow: 1;
}
@media (max-width: 767px) {
  .group-detail-info .group-info__details .r-col {
    flex-direction: row;
    margin: 0 0.5rem;
  }
}
.group-detail-info .group-info__name {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #314154;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 1rem;
}
.group-detail-info .group-info__owner {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #727f8e;
  margin-bottom: 1rem;
}
.group-detail-info .group-info__owner:before {
  content: "\e927";
  font-size: 1rem;
  margin-right: 0.5rem;
}
.group-detail-info .group-info__status {
  margin-bottom: 1rem;
}
.group-detail-info .group-info__status a {
  display: flex;
  width: fit-content;
  align-items: center;
  background: rgba(255, 171, 0, 0.1);
  border-radius: 0.625rem;
  height: 26px;
  padding: 0 0.75rem;
  font-size: 0.875rem;
  color: #ffab00;
}
.group-detail-info .group-info__status a:after {
  content: "\e907";
  margin-left: 0.5rem;
  font-size: 0.625rem;
}
.group-detail-info .group-info__status.joined a {
  font-weight: 500;
  background: #e9eeff;
  color: #0046c2;
}
.group-detail-info .group-info__status.joined .icon-group_checked:before {
  font-size: 1.125rem;
  margin-right: 0.5rem;
}
.group-detail-info .group-info-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row {
    flex-direction: row;
    justify-content: space-between;
  }
}
.group-detail-info .group-info-row .statistical {
  display: flex;
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .statistical {
    width: 80%;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
}
.group-detail-info .group-info-row .statistical-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  flex: 0 0 auto;
  width: 33.3333333333%;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .statistical-item {
    flex-direction: row;
  }
}
.group-detail-info .group-info-row .statistical-item .num {
  line-height: 1.5rem;
  font-size: 1rem;
  color: #212b36;
  font-weight: 600;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .statistical-item .num {
    margin-left: 0.5rem;
  }
}
.group-detail-info .group-info-row .statistical-item .lb {
  font-size: 0.875rem;
  color: #727f8e;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .statistical-item .lb {
    margin-left: 0.5rem;
  }
}
.group-detail-info .group-info-row .statistical-item .ico {
  flex-shrink: 0;
}
.group-detail-info .group-info-row .statistical-item .ico:before {
  display: inline-block;
  content: "";
  background-position: left center;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .statistical-item .ico:before {
    width: 40px;
    height: 40px;
  }
}
.group-detail-info .group-info-row .statistical .km .ico:before {
  background-image: url(../../images/icons/road.svg);
  background-size: 35px 35px;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .statistical .km .ico:before {
    background-size: 40px;
  }
}
.group-detail-info .group-info-row .statistical .members .ico:before {
  background-image: url(../../images/icons/group.svg);
  background-size: 35px 35px;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .statistical .members .ico:before {
    background-size: 40px;
  }
}
.group-detail-info .group-info-row .statistical .rank .ico:before {
  background-image: url(../../images/icons/rank.svg);
  background-size: 35px 35px;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .statistical .rank .ico:before {
    background-size: 40px;
  }
}
.group-detail-info .group-info-row .btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .btn-group {
    justify-content: flex-end;
    width: calc(20% - 0.5rem);
  }
}
.group-detail-info .group-info-row .btn-group .setting-more {
  position: relative;
}
.group-detail-info .group-info-row .btn-group .setting-more-btn {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  color: #727f8e;
}
.group-detail-info .group-info-row .btn-group .setting-more-btn:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\e92d";
  font-size: 1.25rem;
  height: 40px;
}
.group-detail-info .group-info-row .btn-group .setting-more-list {
  display: none;
  z-index: 6;
  position: absolute;
  top: 3rem;
  right: 0;
  width: 160px;
  background: #fff;
  width: 160px;
  border-radius: 0.25rem;
  border: 1px solid #dbdfe2;
  word-break: break-word;
}
@media (min-width: 768px) {
  .group-detail-info .group-info-row .btn-group .setting-more-list {
    width: max-content;
    max-width: 500px;
  }
}
.group-detail-info .group-info-row .btn-group .setting-more-list:before {
  position: absolute;
  top: -8px;
  right: 16px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #f7f7f7 transparent;
}
.group-detail-info .group-info-row .btn-group .setting-more-list > li {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 16px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  color: #314154;
}
.group-detail-info .group-info-row .btn-group .setting-more-list > li:hover {
  color: #de3618 !important;
  background-color: #f4f6f8;
}
.group-detail-info .group-info-row .btn-group .setting-more-list > li:not(:first-child) {
  border-top: 1px dashed rgba(151, 151, 151, 0.2);
}
.group-detail-info .group-info-row .btn-group .uiselector.open .setting-more-btn {
  color: #002f8b;
  background-color: #e9eeff;
  border-color: #002f8b;
  border-radius: 0.25rem;
}
.group-detail-info .group-info-row .btn-group .uiselector.open .setting-more-list {
  display: block;
}
.group-detail-info .group-info__introduce .title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #314154;
  margin-bottom: 1rem;
}
.group-detail-info .group-info__introduce .desc {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
}
.group-detail-info .group-info__introduce .desc .txt {
  min-width: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #314154;
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 4.5rem;
}
.group-detail-info .group-info__introduce .desc .see-more {
  align-self: center;
}
.group-detail-info .group-info__introduce .desc .see-more:hover {
  transform: scale(1.2, 1.2);
}
.group-detail-info .group-info__introduce .desc .see-more:before {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #e9eeff;
  content: "\e907";
  font-size: 0.625rem;
  color: #0046c2;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.group-detail-info .group-info__introduce .desc .see-more.active:before {
  font-size: 0.625rem;
  content: "\e907";
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.group-detail-info .group-info__introduce .desc .see-more.active + .txt {
  height: auto;
  max-height: inherit;
  -webkit-line-clamp: inherit;
}

/*# sourceMappingURL=group_detail.css.map */
