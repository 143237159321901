.dropzone.dz-max-files-reached .dropzone-preview-add:before, .dropzone.dz-max-files-reached .dropzone-preview-edit:before, .dropzone-fallback-needsClick .icon-cloud-upload::before, .create-event-challenges-box .sports .card.disabled .txt:after, .create-event-challenges-box .sports .card .type.triathlon:before, .create-event-challenges-box .sports .card .type.swim:before, .create-event-challenges-box .sports .card .type.bike:before, .create-event-challenges-box .sports .card .type.run:before, .create-event-challenges-box-body .rules .frm-txt .calendar:before, .create-event-challenges-box-body .rules .frm-txt .clock:before, .create-event-challenges-box-body .description:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropzone.dz-max-files-reached .dropzone-preview-add .file-upload-input, .dropzone.dz-max-files-reached .dropzone-preview-edit .file-upload-input, .dropzone-fallback-needsClick .file-upload-input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  outline: none;
  cursor: pointer;
}

.create-event-challenges {
  background-color: #f9fafb;
  padding: 64px 70px;
}
@media (max-width: 1050px) {
  .create-event-challenges {
    padding: 50px 16px;
  }
}
.create-event-challenges-wrap {
  width: 100%;
  margin: 0 auto;
  max-width: 950px;
}
.create-event-challenges-wrap .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.create-event-challenges-wrap .row > * {
  flex-shrink: 0;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.create-event-challenges .page-intro-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 567px) {
  .create-event-challenges .page-intro-box {
    flex-direction: row !important;
  }
}
.create-event-challenges .page-intro-title {
  color: #212b36;
  font-size: 2rem;
  font-weight: 600;
}
@media (max-width: 567px) {
  .create-event-challenges .page-intro-right {
    margin-top: 1rem;
  }
}
.create-event-challenges-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1.5rem 0;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
}
.create-event-challenges-box-header {
  margin-bottom: 1.25rem;
}
.create-event-challenges-box-header .title {
  color: #212b36;
  font-size: 1.125rem;
  font-weight: 600;
}
.create-event-challenges-box-body .list-group {
  display: flex;
  flex-direction: column;
}
.create-event-challenges-box-body .list-group-item {
  position: relative;
  display: block;
  line-height: 1.5rem;
  font-size: 1rem;
  color: #727f8e;
  font-weight: normal;
  padding: 1.5rem 0;
}
.create-event-challenges-box-body .list-group-item:first-child {
  padding-top: 0 !important;
}
.create-event-challenges-box-body .list-group-item:not(:last-child) {
  border-bottom: 1px solid #e7eaec;
}
.create-event-challenges-box-body .list-group-item .txt {
  color: #727f8e;
}
.create-event-challenges-box-body .list-group-item .title {
  color: #212b36;
  font-size: 1rem !important;
  font-weight: 600;
}
.create-event-challenges-box-body .list-group-item .title-icon {
  color: #0046c2;
  vertical-align: bottom;
}
.create-event-challenges-box-body .list-group-item .title .icon-group {
  font-size: 1.5rem;
}
.create-event-challenges-box-body .description {
  color: #727f8e;
  font-weight: normal;
  margin-top: 1rem;
}
.create-event-challenges-box-body .description:before {
  content: "\e108";
  margin-right: 0.625rem;
}
.create-event-challenges-box-body .rules {
  line-height: 1.5rem;
}
.create-event-challenges-box-body .rules .frm-checkbox {
  margin-bottom: 1rem;
}
.create-event-challenges-box-body .rules .frm-checkbox .txt {
  color: #212b36;
  font-size: 1rem;
  font-weight: 600;
}
.create-event-challenges-box-body .rules .frm-checkbox .txt-fw {
  color: #314154;
  font-size: 1rem;
  font-weight: normal;
}
.create-event-challenges-box-body .rules .frm-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.1rem;
}
.create-event-challenges-box-body .rules .frm-row .lb {
  font-size: 1rem;
  color: #314154;
  margin-bottom: 0.25rem;
}
.create-event-challenges-box-body .rules .frm-txt.has-form-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.create-event-challenges-box-body .rules .frm-txt .clock {
  position: relative;
}
.create-event-challenges-box-body .rules .frm-txt .clock:before {
  position: absolute;
  top: calc(100% - 10px);
  left: calc(100% - 30px);
  content: "\e109";
  color: #727f8e;
  font-size: 20px;
}
.create-event-challenges-box-body .rules .frm-txt .calendar {
  position: relative;
}
.create-event-challenges-box-body .rules .frm-txt .calendar:before {
  position: absolute;
  top: calc(100% - 10px);
  left: calc(100% - 30px);
  content: "\e111";
  color: #727f8e;
  font-size: 20px;
}
.create-event-challenges-box-body .rules .frm-radio-group-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.create-event-challenges-box-body .rules-title {
  margin-bottom: 0.75rem;
  color: #212b36;
  font-size: 1.125rem;
  font-weight: 600;
}
.create-event-challenges-box-body .rules-valid-frm {
  background: #f4f6f8;
  margin-top: 1.5rem;
  margin-left: 2rem;
  border-color: #e7eaec;
  box-shadow: none;
}
.create-event-challenges-box-body .rules-valid-frm-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  padding: 1.25rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .create-event-challenges-box-body .rules-valid-frm-item {
    flex-direction: column !important;
  }
}
.create-event-challenges-box-body .rules-valid-frm .frm-txt-lb {
  color: #727f8e;
  margin-bottom: 0.25rem;
}
@media (min-width: 767px) {
  .create-event-challenges-box-body .rules-valid-frm .frm-txt input.distance {
    width: 120px;
  }
}
@media (min-width: 767px) {
  .create-event-challenges-box-body .rules-valid-frm .frm-txt input.speed {
    width: 220px;
  }
}
@media (min-width: 767px) {
  .create-event-challenges-box-body .rules-valid-frm .frm-txt input.spm, .create-event-challenges-box-body .rules-valid-frm .frm-txt input.bpm {
    width: 100px;
  }
}
.create-event-challenges-box-body .rules-valid-frm .frm-txt .form-text {
  color: #314154;
  margin-left: 0.5rem;
}
@media (min-width: 768px) {
  .create-event-challenges-box-body .rules-valid-frm .frm-txt .form-text.space {
    margin-left: 1.5rem;
  }
}
.create-event-challenges-box-body .rules-valid-frm .frm-txt input.number {
  /*for absolutely positioning spinners*/
  position: relative;
}
.create-event-challenges-box-body .rules-valid-frm .frm-txt input.number::-webkit-inner-spin-button, .create-event-challenges-box-body .rules-valid-frm .frm-txt input.number::-webkit-outer-spin-button {
  opacity: 1;
  -webkit-appearance: inner-spin-button !important;
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.create-event-challenges-box-body .rules-time-frm-item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}
@media (max-width: 767px) {
  .create-event-challenges-box-body .rules-time-frm-item {
    flex-direction: column !important;
  }
}
@media (max-width: 767px) {
  .create-event-challenges-box-body .rules-time-frm-item .frm-txt {
    padding: 0.5rem 0;
  }
}
.create-event-challenges-box .sports .card {
  min-height: 130px;
  height: 100% !important;
}
.create-event-challenges-box .sports .card-header {
  position: absolute;
  top: 0;
  right: 0;
}
.create-event-challenges-box .sports .card-header .icon-triangle {
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -1px;
  font-size: 2.25rem;
  color: #0046c2;
}
.create-event-challenges-box .sports .card-header .icon-check_thick {
  position: absolute;
  z-index: 2;
  right: 6px;
  top: 6px;
  font-size: 0.625rem;
  color: #fff;
}
.create-event-challenges-box .sports .card-body {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 1.5rem;
}
.create-event-challenges-box .sports .card-body .txt {
  flex: 1;
  color: #919eab;
  padding-top: 0.75rem;
  font-weight: 600;
}
.create-event-challenges-box .sports .card .type {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  color: #727f8e;
  background: #f9fafb;
}
.create-event-challenges-box .sports .card .type.run:before {
  position: absolute;
  content: "\e91e";
  font-size: 24px;
}
.create-event-challenges-box .sports .card .type.bike:before {
  position: absolute;
  content: "\e939";
  font-size: 24px;
}
.create-event-challenges-box .sports .card .type.swim:before {
  position: absolute;
  content: "\e938";
  font-size: 24px;
}
.create-event-challenges-box .sports .card .type.triathlon:before {
  position: absolute;
  content: "\e937";
  font-size: 24px;
}
.create-event-challenges-box .sports .card.active {
  border-color: #0046c2 !important;
}
.create-event-challenges-box .sports .card.active .type {
  background: linear-gradient(315.82deg, #0046C2 10.92%, #6CA7FF 120%);
  color: #fff;
}
.create-event-challenges-box .sports .card.active .txt {
  color: #212b36 !important;
}
.create-event-challenges-box .sports .card.disabled .txt:after {
  content: "\e108";
  font-size: 0.875rem;
  margin-left: 0.625rem;
}
.create-event-challenges .action {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
}
@media (min-width: 568px) {
  .create-event-challenges .action {
    flex-direction: row !important;
  }
}
.create-event-challenges .action-create {
  padding: 0.5rem 2rem;
  margin-left: 0.75rem;
}
@media (max-width: 567px) {
  .create-event-challenges .action-create {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0 !important;
  }
}
.create-event-challenges .action-cancel {
  padding: 0.5rem 2rem;
}
@media (max-width: 567px) {
  .create-event-challenges .action-cancel {
    width: 100%;
  }
}

input[type=file],
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.dropzone {
  position: relative;
  border: 2px dashed #dbdfe2;
  background: #f9fafb;
  box-sizing: border-box;
  border-radius: 8px;
  height: 425px;
  margin-bottom: 1rem;
  padding: 1.5rem;
}
@media (max-width: 767px) {
  .dropzone {
    padding: 0.5rem;
    min-height: 150px;
    height: auto;
  }
}
.dropzone-fallback {
  margin: 2rem 0;
  text-align: center;
  padding: 2rem 1rem;
}
@media (max-width: 767px) {
  .dropzone-fallback {
    margin: 1rem 0 !important;
    padding: 0 !important;
  }
}
.dropzone-fallback-title {
  color: #314154;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.125rem;
}
.dropzone-fallback-needsClick {
  position: relative;
  margin: 0 auto 1rem auto;
  cursor: pointer;
  width: 50%;
  min-height: 100px;
  padding: 2rem 1rem;
  border: 1px dashed #dbdfe2;
  box-sizing: border-box;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .dropzone-fallback-needsClick {
    width: 90%;
  }
}
.dropzone-fallback-needsClick .icon-cloud-upload {
  display: block;
  color: #919eab;
}
.dropzone-fallback-needsClick .icon-cloud-upload::before {
  content: "\e110";
  font-size: 2rem;
}
.dropzone-fallback-needsClick .msg-select {
  color: #0046c2;
  font-weight: 600;
  margin-left: 0.5rem;
}
.dropzone-preview {
  visibility: hidden;
  height: 0;
  width: 0;
}
.dropzone-preview-single {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}
.dropzone-preview-cover {
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
}
.dropzone-preview-img {
  height: 0;
  width: 0;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.5rem;
}

.dropzone.dz-max-files-reached {
  border: none;
}
.dropzone.dz-max-files-reached .dropzone-fallback {
  opacity: 0 !important;
}
.dropzone.dz-max-files-reached .dropzone-preview {
  visibility: visible;
  height: auto;
  width: auto;
}
.dropzone.dz-max-files-reached .dropzone-preview-cover {
  height: 100% !important;
  width: 100% !important;
}
.dropzone.dz-max-files-reached .dropzone-preview-img {
  width: 100% !important;
  height: 100% !important;
}
.dropzone.dz-max-files-reached .dropzone-preview-edit {
  position: absolute;
  cursor: pointer;
  color: #fff;
  z-index: 6;
  right: 1rem;
  top: 1rem;
  background: rgba(33, 43, 54, 0.8);
}
.dropzone.dz-max-files-reached .dropzone-preview-edit:before {
  content: "\e925";
}
.dropzone.dz-max-files-reached .dropzone-preview-edit .txt {
  margin-left: 0.5rem;
  color: #fff;
}
.dropzone.dz-max-files-reached .dropzone-preview-add {
  position: absolute;
  cursor: pointer;
  color: #fff;
  z-index: 6;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(33, 43, 54, 0.8);
}
.dropzone.dz-max-files-reached .dropzone-preview-add:before {
  content: "\e929";
}
.dropzone.dz-max-files-reached .dropzone-preview-add .txt {
  margin-left: 0.5rem;
  color: #fff;
}

/*# sourceMappingURL=create_event_challenges.css.map */
