.manage-box__body .report-note:before, .manage-box__body .manage-box__sub .manage-card .group-info__owner:before, .manage-box__body .manage-box__sub .manage-card .btn-del:before, .manage-box__body .manage-box__sub .manage-card .btn-block:before, .manage-box__body .manage-box__sub .manage-card .btn-dropdown .setting-more-btn:before, .manage-box__body .frm-row__item.search:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.manage-box__body .manage-box__sub .manage-card .group-info__name, .manage-box__body .manage-box__sub .manage-card .info__name {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.event-detail-tabs .manage-wrap {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
}
.manage-wrap .btn-w-120 {
  width: 120px;
}
.manage-box {
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
}
.manage-box .title {
  font-weight: 600;
  font-size: 1.25rem;
  color: #212b36;
}
.manage-box .title-sub-1 {
  font-weight: 600;
  font-size: 1.125rem;
  color: #212b36;
  margin-bottom: 1rem;
}
.manage-box__header {
  padding: 1.25rem 1rem 0 1rem;
}
.manage-box__body .content {
  padding: 1rem;
}
@media (min-width: 768px) {
  .manage-box__body .content {
    padding: 1rem 1.5rem;
  }
}
.manage-box__body .content .tabs-v2-menu {
  margin: 0 -1rem 1.5rem;
}
.manage-box__body .content .tabs-v2-menu.tab-pills .left {
  margin: 0;
}
.manage-box__body .content .tabs-v2-menu.tab-pills .left .menu-item {
  min-width: 170px;
}
@media (max-width: 767px) {
  .manage-box__body .content .tabs-v2-menu.tab-pills .left .menu-item {
    min-width: 100px;
  }
}
.manage-box__body .frm-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 1rem 0;
}
@media (min-width: 768px) {
  .manage-box__body .frm-row {
    flex-direction: row !important;
  }
}
.manage-box__body .frm-row__item {
  position: relative;
}
.manage-box__body .frm-row__item.search {
  position: relative;
  height: 40px;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .manage-box__body .frm-row__item.search {
    margin-bottom: 0 !important;
    margin-right: 0.75rem;
  }
}
.manage-box__body .frm-row__item.search:before {
  position: absolute;
  left: 10px;
  top: calc(50% - 10px + 0px);
  font-size: 1.25rem;
  content: "\e91f";
  color: #727f8e;
}
.manage-box__body .frm-row__item.search > input {
  background: #f9fafb;
  width: 100%;
  height: 100%;
  padding: 15px 45px 15px 45px;
  color: #000;
  font-size: 16px;
  border: 1px solid #dbdfe2;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .manage-box__body .frm-row__item.search > input {
    min-width: 400px;
  }
}
.manage-box__body .pending-approval__title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 0;
}
@media (min-width: 768px) {
  .manage-box__body .pending-approval__title {
    flex-direction: row !important;
  }
}
@media (max-width: 767px) {
  .manage-box__body .pending-approval__title .btn-approve-all {
    margin-top: 1rem;
    width: 100%;
  }
}
.manage-box__body .manage-box__sub {
  padding: 1rem 0;
}
.manage-box__body .manage-box__sub:not(:last-child) {
  border-bottom: 1px solid #e7eaec;
}
.manage-box__body .manage-box__sub .title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #212b36;
  margin-bottom: 1rem;
}
.manage-box__body .manage-box__sub .list-group {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin: -1rem calc(-0.5 * var(--bs-gutter-x)) 1rem;
}
.manage-box__body .manage-box__sub .list-group-item {
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (max-width: 767px) {
  .manage-box__body .manage-box__sub .list-group-item {
    width: 100%;
  }
}
.manage-box__body .manage-box__sub .manage-card {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #e7eaec;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  flex-direction: row;
}
@media (max-width: 768px) {
  .manage-box__body .manage-box__sub .manage-card {
    text-align: center;
    flex-direction: column;
  }
}
.manage-box__body .manage-box__sub .manage-card.rows-2 {
  flex-wrap: wrap;
}
.manage-box__body .manage-box__sub .manage-card.rows-2 .btn-group {
  flex: 0 0 auto;
  width: 100%;
  margin: 0.625rem 0 0 !important;
}
.manage-box__body .manage-box__sub .manage-card .avatar-item {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
}
.manage-box__body .manage-box__sub .manage-card .info {
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
@media (max-width: 768px) {
  .manage-box__body .manage-box__sub .manage-card .info {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
  }
}
.manage-box__body .manage-box__sub .manage-card .info__name {
  font-weight: 600;
  font-size: 1rem;
  color: #314154;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  max-height: 3rem;
}
.manage-box__body .manage-box__sub .manage-card .info__code {
  font-weight: normal;
  font-size: 1rem;
  color: #314154;
}
.manage-box__body .manage-box__sub .manage-card .btn-group {
  display: flex;
}
@media (max-width: 768px) {
  .manage-box__body .manage-box__sub .manage-card .btn-group {
    margin-left: 0;
    margin-top: 1rem;
    justify-content: center;
  }
}
.manage-box__body .manage-box__sub .manage-card .btn-group-item:not(:last-child) {
  margin-right: 0.75rem;
}
.manage-box__body .manage-box__sub .manage-card .btn-follow, .manage-box__body .manage-box__sub .manage-card .btn-unfollow {
  padding: 0 0.5rem;
  width: max-content;
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown .setting-more-btn {
  width: 40px;
  height: 40px;
  border: 0;
  box-sizing: border-box;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  color: #727f8e;
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown .setting-more-btn:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\e92d";
  font-size: 1.25rem;
  height: 40px;
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown .setting-more-list {
  display: none;
  z-index: 6;
  position: absolute;
  top: 3rem;
  right: 0;
  width: 160px;
  background: #fff;
  width: 240px;
  border-radius: 0.25rem;
  border: 1px solid #dbdfe2;
}
@media (max-width: 768px) {
  .manage-box__body .manage-box__sub .manage-card .btn-dropdown .setting-more-list {
    width: 160px;
  }
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown .setting-more-list:before {
  position: absolute;
  top: -8px;
  right: 16px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #f7f7f7 transparent;
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown .setting-more-list > li {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  line-height: 18px;
  padding: 1rem;
  font-size: 1rem;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  color: #314154;
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown .setting-more-list > li:hover {
  color: #de3618;
  background-color: #f4f6f8;
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown .setting-more-list > li:not(:first-child) {
  border-top: 1px dashed rgba(151, 151, 151, 0.2);
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown .uiselector.open .setting-more-btn {
  color: #002f8b;
  background-color: #e9eeff;
}
.manage-box__body .manage-box__sub .manage-card .btn-dropdown .uiselector.open .setting-more-list {
  display: block;
}
.manage-box__body .manage-box__sub .manage-card .btn-block {
  height: 40px;
  display: flex;
  align-items: center;
}
.manage-box__body .manage-box__sub .manage-card .btn-block:before {
  content: "\e104";
  color: #dbdfe2;
  font-size: 1.125rem;
}
.manage-box__body .manage-box__sub .manage-card .btn-del {
  background: transparent;
  border: 0;
}
.manage-box__body .manage-box__sub .manage-card .btn-del:before {
  content: "\e924";
  color: #727f8e;
  font-size: 1.125rem;
}
.manage-box__body .manage-box__sub .manage-card--block .avatar-item {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.manage-box__body .manage-box__sub .manage-card--block .info__name, .manage-box__body .manage-box__sub .manage-card--block .info__code {
  color: #919eab;
}
.manage-box__body .manage-box__sub .manage-card .group-info {
  flex-grow: 1;
  margin-left: 1rem;
}
@media (max-width: 768px) {
  .manage-box__body .manage-box__sub .manage-card .group-info {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.manage-box__body .manage-box__sub .manage-card .group-info__name {
  font-weight: 600;
  font-size: 1rem;
  color: #314154;
  margin-bottom: 0.5rem;
  max-height: 3rem;
}
.manage-box__body .manage-box__sub .manage-card .group-info__owner {
  font-weight: normal;
  font-size: 1rem;
  color: #727f8e;
}
.manage-box__body .manage-box__sub .manage-card .group-info__owner:before {
  content: "\e927";
  margin-right: 0.5rem;
  color: #727f8e;
}
.manage-box__body .report-note {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  font-size: 1rem;
  color: #314154;
  line-height: 1.5rem;
}
.manage-box__body .report-note:before {
  content: "\e91a";
  color: #0046c2;
  font-size: 1rem;
  padding-right: 0.5rem;
  line-height: 1.5rem;
}

/*# sourceMappingURL=manage.css.map */
