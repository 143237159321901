.bxh-background-2022 {
    background-image: url(https://uprace2.vcdn.vn/web/uprace/images/2022/banner-bxh.png) !important;
}
.bxh-background-2023 {
    background-image: url(https://uprace2.vcdn.vn/web/uprace/images/2023/bxh_cover.png) !important;
}
.banner-event-2022 {
    background-image: url(https://uprace2.vcdn.vn/web/uprace/images/2022/banner-event-2022.jpeg) !important;
}
.banner-event-2023 {
    background-image: url(https://uprace2.vcdn.vn/web/uprace/images/2023/event_banner.png) !important;
}
.info--bxh .top-bxh-content .item.lv1-2022 {
    top: 130px;
    left: calc(50% - 58px);
}
@media (max-width: 480px) {
    .info--bxh .top-bxh-content .item.lv1-2022 {
        top: 84px;
        left: calc(50% - 44px);
    }
}
.info--bxh .top-bxh-content .item.lv2-2022 {
    top: 168px;
    left: calc(50% - 188px);
}
@media (max-width: 480px) {
    .info--bxh .top-bxh-content .item.lv2-2022 {
        top: 109px;
        left: calc(50% - 126px);
    }
}
.info--bxh .top-bxh-content .item.lv3-2022 {
    top: 195px;
    left: calc(50% + 73px);
}
@media (max-width: 480px) {
    .info--bxh .top-bxh-content .item.lv3-2022 {
        top: 125px;
        left: calc(50% + 40px);
    }
}