.complain-detail-box__body .user-info-box .l-col .account-group .right .txt::after, .complain-detail-box__body .user-info-box .l-col .account-group .left .avatar.dot:before, .complain-detail-box__body .user-info-box .l-col .info .details .date.triathlon:before, .complain-detail-box__body .user-info-box .l-col .info .details .date.swim:before, .complain-detail-box__body .user-info-box .l-col .info .details .date.bike:before, .complain-detail-box__body .user-info-box .l-col .info .details .date.walk:before, .complain-detail-box__body .user-info-box .l-col .info .details .date.run:before, .complain-detail-page .back-btn:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.complain-detail-page {
  background-color: #f9fafb;
  padding-top: 64px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1024px) {
  .complain-detail-page {
    padding-top: 32px;
  }
}
.complain-detail-page .back-btn {
  position: relative;
  font-size: 1rem;
  padding: 0 0 0 1rem;
  margin-bottom: 1rem;
}
.complain-detail-page .back-btn:before {
  position: absolute;
  content: "\e922";
  font-size: 0.875rem;
}
.complain-detail-wrap {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0.5rem 3rem;
}
@media (min-width: 1200px) {
  .complain-detail-wrap {
    width: 950px;
  }
}
.complain-detail-box {
  background: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
}
@media (min-width: 768px) {
  .complain-detail-box {
    padding: 1.5rem;
  }
}
.complain-detail-box__header .title {
  font-weight: 600;
  font-size: 1.5rem;
  color: #212b36;
  margin-bottom: 1rem;
}
.complain-detail-box__body .user-info-box {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
  padding: 1rem 0.5rem;
}
@media (min-width: 768px) {
  .complain-detail-box__body .user-info-box {
    padding: 1rem;
  }
}
.complain-detail-box__body .user-info-box .l-col, .complain-detail-box__body .user-info-box .r-col {
  flex: 0 0 auto;
  width: 100%;
}
@media (min-width: 960px) {
  .complain-detail-box__body .user-info-box .l-col, .complain-detail-box__body .user-info-box .r-col {
    width: 50%;
  }
}
.complain-detail-box__body .user-info-box .l-col .info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  line-height: 27px;
}
.complain-detail-box__body .user-info-box .l-col .info .avatar {
  flex-shrink: 0;
}
.complain-detail-box__body .user-info-box .l-col .info .avatar-item {
  border: 1px solid #fff;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .complain-detail-box__body .user-info-box .l-col .info .avatar-item {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.complain-detail-box__body .user-info-box .l-col .info .details {
  flex-grow: 1;
  margin-left: 1rem;
}
.complain-detail-box__body .user-info-box .l-col .info .details .name {
  font-weight: 600;
  font-size: 1.125rem;
  color: #142B43;
}
.complain-detail-box__body .user-info-box .l-col .info .details .team {
  font-size: 1rem;
  color: #8995A1;
}
.complain-detail-box__body .user-info-box .l-col .info .details .date {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.complain-detail-box__body .user-info-box .l-col .info .details .date .txt {
  font-size: 0.875rem;
  color: #8995A1;
}
.complain-detail-box__body .user-info-box .l-col .info .details .date .img {
  margin-left: 0.625rem;
}
.complain-detail-box__body .user-info-box .l-col .info .details .date .img img {
  width: 16px;
  height: 16px;
}
.complain-detail-box__body .user-info-box .l-col .info .details .date.run:before {
  content: "\e91e";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.complain-detail-box__body .user-info-box .l-col .info .details .date.walk:before {
  content: "\e944";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.complain-detail-box__body .user-info-box .l-col .info .details .date.bike:before {
  content: "\e939";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.complain-detail-box__body .user-info-box .l-col .info .details .date.swim:before {
  content: "\e938";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.complain-detail-box__body .user-info-box .l-col .info .details .date.triathlon:before {
  content: "\e937";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.complain-detail-box__body .user-info-box .l-col .info .details .time-desc {
  font-weight: 600;
  font-size: 1.125rem;
  color: #142B43;
}
.complain-detail-box__body .user-info-box .l-col .info .details .mess {
  font-weight: normal;
  font-size: 1rem;
  color: #142B43;
}
.complain-detail-box__body .user-info-box .l-col .img-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem 0;
  overflow: hidden;
  width: 100%;
}
.complain-detail-box__body .user-info-box .l-col .img-list-item {
  position: relative;
  flex: none;
  width: calc(16.6666666667% - 4px);
  padding-top: calc(16.6666666667% - 4px);
}
@media (max-width: 768px) {
  .complain-detail-box__body .user-info-box .l-col .img-list-item {
    width: 60px;
    padding-top: 60px;
    margin-top: 0.5rem;
  }
}
.complain-detail-box__body .user-info-box .l-col .img-list-item a {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.complain-detail-box__body .user-info-box .l-col .img-list-item__size {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.complain-detail-box__body .user-info-box .l-col .img-list-item:not(:last-child) {
  margin-right: 0.25rem;
}
.complain-detail-box__body .user-info-box .l-col .img-list-item.more::before, .complain-detail-box__body .user-info-box .l-col .img-list-item.more .num {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.complain-detail-box__body .user-info-box .l-col .img-list-item.more::before {
  background: rgba(0, 0, 0, 0.7);
  content: "";
  z-index: 2;
}
.complain-detail-box__body .user-info-box .l-col .img-list-item.more .num {
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.complain-detail-box__body .user-info-box .l-col .account-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background: #FFFFFF;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
  margin: 1.5rem 0 1rem;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left .avatar {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 16px;
  height: 16px;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left .avatar-group {
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left .avatar:not(:last-child) {
  margin-right: -4px;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left .avatar.dot {
  position: relative;
  background: #E7EAEC;
  border: 1px solid #F6F7F8;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left .avatar.dot:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  content: "\e92d";
  font-size: 0.5rem;
  color: #727F8E;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left .txt {
  flex-grow: 1;
  margin-left: 0.5rem;
  line-height: 16px;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left .txt a {
  font-weight: 500;
  font-size: 0.875rem;
  color: #8995A1;
}
.complain-detail-box__body .user-info-box .l-col .account-group .left .txt a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.complain-detail-box__body .user-info-box .l-col .account-group .right .txt {
  font-weight: 500;
  font-size: 0.875rem;
  color: #727F8E;
  line-height: 16px;
}
.complain-detail-box__body .user-info-box .l-col .account-group .right .txt::after {
  content: "\e907";
  font-size: 8px;
  margin-left: 0.5rem;
}
.complain-detail-box__body .user-info-box .r-col {
  padding-left: 1.25rem;
}
@media (max-width: 768px) {
  .complain-detail-box__body .user-info-box .r-col {
    padding: 0;
    margin-top: 1rem;
  }
}
.complain-detail-box__body .user-info-box .r-col .km-list, .complain-detail-box__body .user-info-box .r-col .calo-list {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e7eaec;
  border-radius: 8px;
}
.complain-detail-box__body .user-info-box .r-col .km-list-item {
  width: 100%;
}
.complain-detail-box__body .user-info-box .r-col .km-list-item .km-row {
  display: flex;
  height: auto;
  margin: 2px 0;
  width: 100%;
  padding: 0 1rem 0.5rem;
  align-items: flex-end;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .complain-detail-box__body .user-info-box .r-col .km-list-item .km-row {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 70px;
    margin: 0;
    padding: 0;
  }
}
.complain-detail-box__body .user-info-box .r-col .km-list-item .km-row .lb {
  color: #727f8e;
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .complain-detail-box__body .user-info-box .r-col .km-list-item .km-row .lb {
    margin-bottom: 0.5rem;
  }
}
.complain-detail-box__body .user-info-box .r-col .km-list-item .km-row .km {
  margin-top: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
  color: #222831;
}
@media (min-width: 1320px) {
  .complain-detail-box__body .user-info-box .r-col .km-list-item .km-row .km {
    font-size: 1.25rem;
  }
}
.complain-detail-box__body .user-info-box .r-col .km-list-item .km-row + .km-row {
  border: 0;
}
@media (min-width: 768px) {
  .complain-detail-box__body .user-info-box .r-col .km-list-item .km-row + .km-row {
    border-top: 1px solid #e7eaec;
  }
}
@media (min-width: 768px) {
  .complain-detail-box__body .user-info-box .r-col .km-list-item:nth-child(1) {
    width: 30%;
  }
}
.complain-detail-box__body .user-info-box .r-col .km-list-item:nth-child(1) .km-row {
  height: 100%;
}
.complain-detail-box__body .user-info-box .r-col .km-list-item:nth-child(2) {
  border: 0;
}
@media (min-width: 768px) {
  .complain-detail-box__body .user-info-box .r-col .km-list-item:nth-child(2) {
    width: 35%;
    border-right: 1px solid #e7eaec;
    border-left: 1px solid #e7eaec;
  }
}
.complain-detail-box__body .user-info-box .r-col .km-list-item:nth-child(3) {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .complain-detail-box__body .user-info-box .r-col .km-list-item:nth-child(3) {
    width: 35%;
    margin-bottom: 0;
  }
}
.complain-detail-box__body .user-info-box .r-col .calo-list {
  padding: 0.5rem;
  margin: 1rem 0;
}
.complain-detail-box__body .user-info-box .r-col .calo-list-item {
  display: flex;
  flex: 0 0 auto;
  width: 50%;
}
@media (max-width: 767px) {
  .complain-detail-box__body .user-info-box .r-col .calo-list-item {
    justify-content: space-between;
    width: 100%;
  }
}
.complain-detail-box__body .user-info-box .r-col .calo-list .lb {
  font-weight: normal;
  font-size: 13px;
  color: #8995A1;
  line-height: 1.5rem;
}
@media (min-width: 768px) {
  .complain-detail-box__body .user-info-box .r-col .calo-list .lb {
    width: 65%;
  }
}
.complain-detail-box__body .user-info-box .r-col .calo-list .num {
  font-weight: normal;
  font-size: 0.875rem;
  color: #222831;
  line-height: 1.5rem;
}
.complain-detail-box__body .speed-map {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0;
}
.complain-detail-box__body .speed-map .speed-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
  width: 348px;
  margin-right: 34px;
}
@media (max-width: 768px) {
  .complain-detail-box__body .speed-map .speed-box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.complain-detail-box__body .speed-map .speed-box .title {
  display: flex;
  background: #dbdfe2;
  width: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.complain-detail-box__body .speed-map .speed-box .title li {
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  color: #142B43;
}
@media (max-width: 768px) {
  .complain-detail-box__body .speed-map .speed-box .title li {
    font-size: 0.875rem;
  }
}
.complain-detail-box__body .speed-map .speed-box .title li:first-child {
  width: 50px;
}
.complain-detail-box__body .speed-map .speed-box .title li:last-child {
  flex-grow: 1;
}
.complain-detail-box__body .speed-map .speed-box .list {
  padding: 1rem 0;
}
.complain-detail-box__body .speed-map .speed-box .list li {
  display: flex;
  line-height: 17px;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}
.complain-detail-box__body .speed-map .speed-box .list li .km {
  width: 50px;
  padding: 0.1875rem 0 0 1rem;
}
.complain-detail-box__body .speed-map .speed-box .list li .speed {
  flex-grow: 1;
  display: flex;
}
.complain-detail-box__body .speed-map .speed-box .list li .speed .time, .complain-detail-box__body .speed-map .speed-box .list li .speed .long {
  line-height: 17px;
}
.complain-detail-box__body .speed-map .speed-box .list li .speed .time {
  padding-top: 0.1875rem;
  width: 50px;
  font-size: 0.875rem;
}
.complain-detail-box__body .speed-map .speed-box .list li .speed .long {
  flex-grow: 1;
}
.complain-detail-box__body .speed-map .speed-box .list li .speed .long .percent {
  display: block;
  background: #5edfff;
  height: 20px;
}
.complain-detail-box__body .speed-map .road-map {
  flex-grow: 1;
  align-self: center;
  height: 100%;
  width: calc(100% - 382px);
  border-radius: 0.5rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
}
@media (max-width: 768px) {
  .complain-detail-box__body .speed-map .road-map {
    width: 100%;
  }
}
.complain-detail-box__body .speed-map .road-map__img {
  border-radius: 0.5rem;
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .complain-detail-box__body .speed-map .road-map__img {
    max-height: 250px;
  }
}
.complain-detail-box__body .choose-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.complain-detail-box__body .choose-list .frm-radio {
  flex: 0 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .complain-detail-box__body .choose-list .frm-radio {
    flex: 0 0 auto;
    width: 50%;
  }
}
.complain-detail-box__footer {
  margin-top: 1.5rem;
  border-top: 1px solid #e7eaec;
}
.complain-detail-box__footer .btn-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 1rem 0 0;
}
.complain-detail-box__footer .btn-handle {
  min-width: 228px;
}

/*# sourceMappingURL=complain_details_v2.css.map */
