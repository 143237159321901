.yours-v2__activity .activity-list .action-row > ul > li > button:before, .yours-v2__activity .activity-list .account-group .avatar.dot:before, .yours-v2__activity .activity-list-item .info-details__date.triathlon:before, .yours-v2__activity .activity-list-item .info-details__date.swim:before, .yours-v2__activity .activity-list-item .info-details__date.bike:before, .yours-v2__activity .activity-list-item .info-details__date.walk:before, .yours-v2__activity .activity-list-item .info-details__date.run:before, .yours-v2__activity .activity-list-item .box .action .setting-more-btn:before, .yours-v2__chart .summary .notify .ico:before, .yours-v2-activities .list-group-item .desc__see-now:after, .yours-v2-info .right .box-rank:before, .yours-v2-info .right .box-activity:before, .yours-v2-info .right .box-km:before, .yours-v2-wrap .back-btn:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.yours-v2-info .right .box-group {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
}
@media (min-width: 768px) {
  .yours-v2-info .right .box-group {
    --bs-gutter-x: 1.5rem;
  }
}

.yours--activity .activity-list {
  margin-top: 0;
}
.yours--activity .activity-list ul li .setting-more {
  display: flex;
  align-items: center;
}
.yours--activity .activity-list ul li .action {
  margin-right: 0.5rem;
}
.yours--activity .activity-empty {
  margin-top: 2rem;
}

.yours-v2-page {
  padding-top: 64px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.yours-v2-wrap {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 0 0.5rem;
}
@media (min-width: 768px) {
  .yours-v2-wrap {
    padding: 0.5rem 3rem;
  }
}
@media (min-width: 992px) {
  .yours-v2-wrap {
    padding: 0.5rem 6rem;
  }
}
@media (min-width: 1200px) {
  .yours-v2-wrap {
    padding: 0.5rem 0;
    width: 1110px;
  }
}
.yours-v2-wrap .yours--activity {
  padding: 0;
  margin: 0;
}
.yours-v2-wrap .back-btn {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  color: #314154;
  line-height: 1.125rem;
}
.yours-v2-wrap .back-btn:hover {
  color: #0386fa;
}
.yours-v2-wrap .back-btn:before {
  content: "\e922";
  font-size: 0.625rem;
  line-height: 1rem;
}
.yours-v2-wrap .run-details li .txt {
  color: #142B43;
}
.yours-v2-info {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5rem 1.5rem;
  border-bottom: 1px solid #e7eaec;
}
.yours-v2-info .left {
  flex: 0 0 auto;
  width: 50%;
}
@media (max-width: 767px) {
  .yours-v2-info .left {
    margin-bottom: 1rem;
    width: 100%;
  }
}
.yours-v2-info .left .avatar-item {
  max-width: 100%;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
  background: linear-gradient(355.87deg, #E9E9E9 -31.62%, rgba(255, 255, 255, 0) 63.12%);
}
.yours-v2-info .left .info__name {
  font-weight: 600;
  font-size: 1.5rem;
  color: #314154;
  margin-bottom: 1rem;
}
.yours-v2-info .left .info__company {
  font-size: 1rem;
  color: #314154;
}
.yours-v2-info .right {
  flex: 0 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .yours-v2-info .right {
    width: 50%;
  }
}
.yours-v2-info .right .box {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  word-wrap: break-word;
  padding: 1rem 0.5rem;
  background: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 8px rgba(63, 63, 68, 0.08);
  height: 100%;
}
@media (min-width: 768px) {
  .yours-v2-info .right .box {
    padding: 1.5rem 1rem;
  }
}
.yours-v2-info .right .box .info {
  display: flex;
  flex-direction: column;
}
.yours-v2-info .right .box-group .col {
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.yours-v2-info .right .box .num {
  font-weight: 600;
  font-size: 1rem;
  color: #314154;
}
@media (min-width: 768px) {
  .yours-v2-info .right .box .num {
    font-size: 1.25rem;
  }
}
.yours-v2-info .right .box .unit {
  font-size: 0.75rem;
  color: #727f8e;
}
@media (min-width: 768px) {
  .yours-v2-info .right .box .unit {
    font-size: 1rem;
  }
}
.yours-v2-info .right .box-km {
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../../images/yours/road.png);
  background-size: 100% auto;
  background-position: center calc(100% + 1px);
  background-repeat: no-repeat;
}
.yours-v2-info .right .box-km:before {
  content: "\e93d";
  color: #0046c2;
  font-size: 1.5rem;
}
.yours-v2-info .right .box-km .info {
  margin-top: 1.25rem;
  flex-direction: row;
}
.yours-v2-info .right .box-km .info .unit {
  margin-left: 0.5rem;
}
.yours-v2-info .right .box-activity {
  margin-bottom: 1rem;
}
.yours-v2-info .right .box-activity:before {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "\e910";
  color: #50b83c;
  width: 32px;
  height: 32px;
  font-size: 1rem;
  background: rgba(80, 184, 60, 0.1);
  border-radius: 0.5rem;
}
@media (min-width: 768px) {
  .yours-v2-info .right .box-activity:before {
    width: 48px;
    height: 48px;
    font-size: 1.5rem;
  }
}
.yours-v2-info .right .box-activity .info {
  margin-left: 0.5rem;
}
.yours-v2-info .right .box-activity .info .num {
  padding-bottom: 0.75rem;
}
.yours-v2-info .right .box-rank:before {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "\e93c";
  color: #ffab00;
  width: 32px;
  height: 32px;
  font-size: 1rem;
  background: rgba(255, 171, 0, 0.1);
  border-radius: 0.5rem;
}
@media (min-width: 768px) {
  .yours-v2-info .right .box-rank:before {
    width: 48px;
    height: 48px;
    font-size: 1.5rem;
  }
}
.yours-v2-info .right .box-rank .info {
  margin-left: 0.5rem;
}
.yours-v2-info .right .box-rank .info .num {
  padding-bottom: 0.75rem;
}
.yours-v2-activities {
  margin-top: 0.5rem;
  padding: 0 0.5rem 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}
.yours-v2-activities .list-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.yours-v2-activities .list-group-item {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  position: relative;
  display: block;
  padding: 1rem 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: #212b36;
  border-bottom: 1px solid #e7eaec;
}
.yours-v2-activities .list-group-item:first-child {
  padding-top: 0.5rem;
}
.yours-v2-activities .list-group-item .desc {
  display: flex;
  justify-content: space-between;
  line-height: 1.75rem;
}
.yours-v2-activities .list-group-item .desc__title {
  font-weight: 600;
  font-size: 1rem;
  color: #212b36;
}
@media (min-width: 768px) {
  .yours-v2-activities .list-group-item .desc__title {
    font-size: 1.125rem;
  }
}
.yours-v2-activities .list-group-item .desc__see-now {
  position: relative;
  padding-right: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  .yours-v2-activities .list-group-item .desc__see-now {
    font-size: 1rem;
  }
}
.yours-v2-activities .list-group-item .desc__see-now:after {
  position: absolute;
  top: 0.625rem;
  right: 0;
  content: "\e907";
  font-size: 0.625rem;
}
.yours-v2__chart .title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #212b36;
}
.yours-v2__chart .summary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;
}
.yours-v2__chart .summary .lb {
  font-size: 1rem;
  color: #314154;
}
.yours-v2__chart .summary .num {
  font-weight: 600;
  font-size: 1.25rem;
  color: #212b36;
  line-height: 1.875rem;
  margin-left: 0.5rem;
}
.yours-v2__chart .summary .unit {
  font-size: 1rem;
  color: #314154;
  margin-left: 0.5rem;
}
.yours-v2__chart .summary .notify {
  position: relative;
  width: 17px;
  height: auto;
  margin-left: 0.5rem;
  padding-top: 0.375rem;
  overflow: hidden;
}
.yours-v2__chart .summary .notify:hover {
  overflow: inherit;
}
.yours-v2__chart .summary .notify:hover .tooltip-box {
  opacity: 1;
}
.yours-v2__chart .summary .notify .ico {
  position: relative;
  width: 16px;
  height: 16px;
}
.yours-v2__chart .summary .notify .ico:before {
  position: absolute;
  top: -0.25rem;
  left: 0;
  content: "\e91a";
  color: #0046c2;
  font-size: 1rem;
}
.yours-v2__chart .summary .notify .tooltip-box {
  position: absolute;
  top: 2rem;
  left: calc(50% - 170px);
  width: 340px;
  z-index: 6;
}
@media (max-width: 1050px) {
  .yours-v2__chart .summary .notify .tooltip-box {
    left: calc(50% - 145px);
    width: 290px;
  }
}
@media (max-width: 375px) {
  .yours-v2__chart .summary .notify .tooltip-box {
    left: auto;
    right: -30px;
    width: 150px;
  }
  .yours-v2__chart .summary .notify .tooltip-box::before {
    left: auto;
    right: 33px;
  }
}
.yours-v2__chart .years {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.yours-v2__chart .years .color {
  width: 18px;
  height: 18px;
  border-radius: 0.25rem;
}
.yours-v2__chart .years .color--yellow {
  background: #ffab00;
}
.yours-v2__chart .years .color--green {
  margin-left: 0.5rem;
  background: #36cfbb;
}
.yours-v2__chart .years .num {
  margin-left: 0.5rem;
  color: #727f8e;
  font-size: 1.125rem;
}
.yours-v2__chart .chart-block {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .yours-v2__chart .chart-block {
    flex-direction: row;
  }
}
.yours-v2__chart .chart-img {
  flex-grow: 1;
  overflow: hidden;
}
.yours-v2__chart .chart-img-item {
  height: auto;
  max-height: 358px;
}
.yours-v2__chart .statistics-block {
  flex: none;
  display: flex;
  flex-flow: column wrap;
  padding-bottom: 1.5rem;
  margin-top: 1rem;
}
@media (min-width: 992px) {
  .yours-v2__chart .statistics-block {
    align-self: flex-end;
    margin-top: 0;
    margin-left: 1rem;
    min-width: 380px;
  }
}
.yours-v2__chart .statistics-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.yours-v2__chart .statistics-box .title {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 600;
  color: #212b36;
  padding: 0.5rem;
}
.yours-v2__chart .statistics-box .title::before {
  content: "";
  border-radius: 0.25rem;
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
}
.yours-v2__chart .statistics-box .ctn {
  display: flex;
}
.yours-v2__chart .statistics-box .ctn > li {
  position: relative;
  flex: 0 0 auto;
  width: 33.3333333333%;
  padding: 1rem 0.5rem;
  text-align: center;
}
.yours-v2__chart .statistics-box .ctn > li .num {
  font-size: 1.125rem;
  font-weight: 600;
  color: #212b36;
}
.yours-v2__chart .statistics-box .ctn > li .unit {
  margin-top: 0.5rem;
  color: #314154;
  font-size: 0.875rem;
}
.yours-v2__chart .statistics-box.yellow .title {
  background: #fffae5;
}
.yours-v2__chart .statistics-box.yellow .title::before {
  background: #ffab00;
}
.yours-v2__chart .statistics-box.yellow .ctn {
  background: rgba(255, 250, 229, 0.3);
}
.yours-v2__chart .statistics-box.green .title {
  background: #f4f6f8;
}
.yours-v2__chart .statistics-box.green .title::before {
  background: #36cfbb;
}
.yours-v2__chart .statistics-box.green .ctn {
  background: rgba(244, 246, 248, 0.3);
}
.yours-v2__activity .activity-list {
  display: flex;
  flex-direction: column;
}
.yours-v2__activity .activity-list-item {
  position: relative;
  display: block;
  padding: 1rem 0;
}
.yours-v2__activity .activity-list-item:not(:last-child) {
  border-bottom: 1px solid #e7eaec;
}
.yours-v2__activity .activity-list-item .box {
  position: relative;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 1px 3px rgba(63, 63, 68, 0.15), 0 0 8px rgba(63, 63, 68, 0.08);
  border-radius: 10px;
  padding: 0.5rem 1rem 1.25rem;
  margin: 1rem 0 1.25rem;
}
.yours-v2__activity .activity-list-item .box .action {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
  right: 1rem;
}
.yours-v2__activity .activity-list-item .box .action .complain-link {
  font-weight: 600;
  font-size: 1rem;
  color: #0046c2;
  line-height: 1.5rem;
}
.yours-v2__activity .activity-list-item .box .action .complain-link:hover {
  color: #0386fa;
}
.yours-v2__activity .activity-list-item .box .action .setting-more {
  margin-left: 0.5rem;
}
.yours-v2__activity .activity-list-item .box .action .setting-more-btn {
  width: 40px;
  height: 40px;
  border: 0;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  color: #727f8e;
}
.yours-v2__activity .activity-list-item .box .action .setting-more-btn:before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\e92d";
  font-size: 1.25rem;
  height: 40px;
}
.yours-v2__activity .activity-list-item .box .action .setting-more-btn:hover {
  color: #002f8b;
  background-color: #e9eeff;
}
.yours-v2__activity .activity-list-item .box .action .setting-more-list {
  display: none;
  z-index: 6;
  position: absolute;
  top: 3rem;
  right: 0;
  width: 160px;
  background: #fff;
  width: 240px;
  border-radius: 0.25rem;
  border: 1px solid #dbdfe2;
}
@media (max-width: 768px) {
  .yours-v2__activity .activity-list-item .box .action .setting-more-list {
    width: 160px;
  }
}
.yours-v2__activity .activity-list-item .box .action .setting-more-list:before {
  position: absolute;
  top: -8px;
  right: 16px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #f7f7f7 transparent;
}
.yours-v2__activity .activity-list-item .box .action .setting-more-list > li {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  line-height: 18px;
  padding: 1rem;
  font-size: 1rem;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  color: #314154;
}
.yours-v2__activity .activity-list-item .box .action .setting-more-list > li:hover {
  color: #de3618;
  background-color: #f4f6f8;
}
.yours-v2__activity .activity-list-item .box .action .setting-more-list > li:not(:first-child) {
  border-top: 1px dashed rgba(151, 151, 151, 0.2);
}
.yours-v2__activity .activity-list-item .box .action .uiselector.open .setting-more-btn {
  color: #002f8b;
  background-color: #e9eeff;
}
.yours-v2__activity .activity-list-item .box .action .uiselector.open .setting-more-list {
  display: block;
}
.yours-v2__activity .activity-list-item .complain-box {
  background: #F4F6F8;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
}
.yours-v2__activity .activity-list-item .complain-box .lb {
  font-weight: 600;
  font-size: 1rem;
  color: #314154;
}
.yours-v2__activity .activity-list-item .complain-box .note {
  line-height: 24px;
  font-weight: normal;
  font-size: 1rem;
  color: #314154;
}
.yours-v2__activity .activity-list-item .info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem 0;
}
.yours-v2__activity .activity-list-item .info-avatar {
  flex-shrink: 0;
  margin-right: 0.5rem;
}
.yours-v2__activity .activity-list-item .info-avatar__item {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.yours-v2__activity .activity-list-item .info-details__name {
  font-weight: 600;
  font-size: 1rem;
  color: #142B43;
  line-height: 1.5rem;
}
.yours-v2__activity .activity-list-item .info-details__date {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.yours-v2__activity .activity-list-item .info-details__date .txt {
  font-size: 0.875rem;
  color: #8995A1;
}
.yours-v2__activity .activity-list-item .info-details__date .img {
  margin-left: 0.625rem;
}
.yours-v2__activity .activity-list-item .info-details__date .img img {
  width: 16px;
  height: 16px;
}
.yours-v2__activity .activity-list-item .info-details__date.run:before {
  content: "\e91e";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.yours-v2__activity .activity-list-item .info-details__date.walk:before {
  content: "\e944";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.yours-v2__activity .activity-list-item .info-details__date.bike:before {
  content: "\e939";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.yours-v2__activity .activity-list-item .info-details__date.swim:before {
  content: "\e938";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.yours-v2__activity .activity-list-item .info-details__date.triathlon:before {
  content: "\e937";
  color: #727f8e;
  font-size: 1rem;
  margin-right: 0.625rem;
}
.yours-v2__activity .activity-list-item .time-desc {
  margin: 1rem 0 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  color: #142B43;
}
.yours-v2__activity .activity-list-item .run-detail-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.yours-v2__activity .activity-list-item .run-detail-item {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.yours-v2__activity .activity-list-item .run-detail-item .lb {
  font-weight: normal;
  font-size: 0.875rem;
  color: #727F8E;
}
.yours-v2__activity .activity-list-item .run-detail-item .txt {
  line-height: 27px;
  font-weight: 600;
  font-size: 1.125rem;
  color: #142B43;
}
.yours-v2__activity .activity-list-item .road-map {
  margin: 1rem 0;
}
.yours-v2__activity .activity-list-item .road-map__img {
  width: 100%;
  height: auto;
  max-height: 250px;
}
@media (min-width: 768px) {
  .yours-v2__activity .activity-list-item .road-map__img {
    max-height: 490px;
  }
}
@media (min-width: 1400px) {
  .yours-v2__activity .activity-list-item .road-map__img {
    max-height: 550px;
  }
}
.yours-v2__activity .activity-list-item .choose-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.yours-v2__activity .activity-list-item .choose-list .frm-radio {
  flex: 0 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .yours-v2__activity .activity-list-item .choose-list .frm-radio {
    flex: 0 0 auto;
    width: 50%;
  }
}
.yours-v2__activity .activity-list-item .btn-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 1rem 0;
}
.yours-v2__activity .activity-list-item .btn-handle {
  min-width: 228px;
}
.yours-v2__activity .activity-list.fail .fail-notify {
  position: relative;
  background: #f4f6f8;
  line-height: 25px;
  margin: 1rem 0;
  padding: 12px 12px 12px 54px;
  font-size: 1.125rem;
  border-radius: 0.5rem;
}
@media (max-width: 1050px) {
  .yours-v2__activity .activity-list.fail .fail-notify {
    line-height: 22px;
    padding: 12px 8px 10px 40px;
    margin-top: 12px;
    font-size: 0.875rem;
  }
}
.yours-v2__activity .activity-list.fail .fail-notify:before {
  background-image: url(../../images/icons/dsq.svg);
  background-size: 100% 100%;
  content: "";
  position: absolute;
  top: 12px;
  left: -7px;
  width: 43px;
  height: 30px;
}
@media (max-width: 1050px) {
  .yours-v2__activity .activity-list.fail .fail-notify:before {
    left: -4px;
    width: 36px;
    height: 25px;
  }
}
.yours-v2__activity .activity-list.fail .fail-notify .mess {
  color: #de3618;
}
.yours-v2__activity .activity-list.fail .fail-notify a {
  color: #0046c2;
  font-weight: 600;
  white-space: nowrap;
}
@media (min-width: 1051px) {
  .yours-v2__activity .activity-list.fail .fail-notify a:hover {
    color: #0386fa;
    text-decoration: underline;
  }
}
.yours-v2__activity .activity-list .account-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
.yours-v2__activity .activity-list .account-group .avatar {
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
}
.yours-v2__activity .activity-list .account-group .avatar-group {
  display: inline-flex;
  flex-direction: row;
  margin-right: 0.5rem;
}
.yours-v2__activity .activity-list .account-group .avatar:not(:last-child) {
  margin-right: -8px;
}
.yours-v2__activity .activity-list .account-group .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.yours-v2__activity .activity-list .account-group .avatar.dot {
  display: block;
  background: #f4f6f8;
  border: 1px solid #f4f6f8;
}
.yours-v2__activity .activity-list .account-group .avatar.dot:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  content: "\e92d";
  font-size: 0.5rem;
  color: #727F8E;
}
.yours-v2__activity .activity-list .account-group .txt {
  font-size: 1rem;
  color: #727f8e;
}
.yours-v2__activity .activity-list .action-row {
  border-top: 1px solid #e7eaec;
}
.yours-v2__activity .activity-list .action-row > ul {
  display: flex;
}
.yours-v2__activity .activity-list .action-row > ul > li {
  width: 33.333%;
  height: 32px;
  padding: 4px 0;
}
.yours-v2__activity .activity-list .action-row > ul > li > button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  height: 24px;
  border-radius: 4px;
}
.yours-v2__activity .activity-list .action-row > ul > li > button.active:before {
  background-image: url(../../images/icons/like_active.svg);
  background-size: 100% 100%;
  content: "";
  width: 15px;
  height: 15px;
}
@media (min-width: 871px) {
  .yours-v2__activity .activity-list .action-row > ul > li > button:hover {
    background: rgba(216, 216, 216, 0.2);
  }
}
.yours-v2__activity .activity-list .action-row > ul > li > button {
  color: #979797;
}
.yours-v2__activity .activity-list .action-row > ul > li > button .num {
  margin-left: 6px;
  padding-top: 3px;
}
.yours-v2__activity .activity-list .action-row > ul > li .like {
  width: calc(100% - 2px);
  margin-right: 2px;
}
.yours-v2__activity .activity-list .action-row > ul > li .like::before {
  content: "\e918";
  font-size: 15px;
}
.yours-v2__activity .activity-list .action-row > ul > li .mess {
  width: calc(100% - 4px);
  margin: 0 2px;
}
.yours-v2__activity .activity-list .action-row > ul > li .mess:before {
  content: "\e919";
  font-size: 15px;
}
.yours-v2__activity .activity-list .action-row > ul > li .share {
  width: calc(100% - 2px);
  margin-left: 2px;
}
.yours-v2__activity .activity-list .action-row > ul > li .share:before {
  content: "\e91d";
  font-size: 14px;
}
.yours-v2__activity .activity-list .action-row + .mess-block {
  padding-top: 16px;
  border-top: 1px solid rgba(151, 151, 151, 0.1);
}
.yours-v2__activity .activity-list .action-row + .mess-seemore, .yours-v2__activity .activity-list .action-row + .reply-seemore {
  display: block;
  position: relative;
  margin-top: 12px;
}
.yours-v2__activity .activity-list .action-row + .mess-seemore:before, .yours-v2__activity .activity-list .action-row + .reply-seemore:before {
  background: #e7eaec;
  position: absolute;
  top: -12px;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
}
@media (min-width: 768px) {
  .yours-v2-details {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (min-width: 992px) {
  .yours-v2-details {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@media (min-width: 1400px) {
  .yours-v2-details {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
.yours-v2-details .title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #212b36;
}
.yours-v2-details .title .num {
  font-weight: normal;
  margin-left: 0.5rem;
}
.yours-v2-details .yours-v2__activity .activity-list-item {
  border-bottom: 0;
}
.yours-v2-details .yours-v2__activity .activity-list-item .box {
  margin: 0;
}
.yours-v2-details .yours-v2__activity .time-desc {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e7eaec;
}

/*# sourceMappingURL=yours_v2.css.map */
