.group__owner:before, .group-event-challenges-popup .popup-body .btn-add:before, .group-event-challenges-popup .popup-body .group-details__size:before, .group-event-challenges-popup .popup-body .group-details__owner:before, .group-event-challenges-popup .popup-body .frm-row__item.search:before, #cancel-create-event .popup-body .icon-info:before, .uprace-popup--v2 .popup-body .icon-info:before, #cancel-create-event .popup-body .icon-warning:before, .uprace-popup--v2 .popup-body .icon-warning:before, #create-event .list-group .virtual-race:before, #create-event .list-group .challenges:before, #create-event .list-group .icon-arrow_thick:before {
  font-family: "uprace" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.group-event-challenges-popup .popup-body .group-details__name {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.group-event-challenges-popup .popup-header, #create-event .popup-header {
  display: flex;
  padding: 1rem !important;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7eaec;
}
.group-event-challenges-popup .popup-header .title, #create-event .popup-header .title {
  font-size: 1.25rem;
  color: #212b36;
  font-weight: 600;
}
.group-event-challenges-popup .popup-header .popup-close, #create-event .popup-header .popup-close {
  position: relative;
  top: 0;
  right: 0;
}

#create-event .popup-body {
  padding: 0 1.5rem;
}
#create-event .popup-footer {
  display: flex;
  border-top: 1px solid #e7eaec;
  padding: 1.5rem;
}
#create-event .popup-footer .btn-close {
  font-weight: 600;
  padding: 0.5rem 1.5rem;
}
#create-event .list-group {
  display: flex;
  flex-direction: column;
}
#create-event .list-group-item {
  position: relative;
  display: block;
  padding: 1.5rem 0;
  line-height: 1.5rem;
  font-size: 1rem;
  color: #727f8e;
}
#create-event .list-group-item:not(:last-child) {
  border-bottom: 1px solid #e7eaec;
}
#create-event .list-group-item .title {
  color: #919eab;
  font-weight: 600;
}
#create-event .list-group-item .icon-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background: #f4f6f8;
  color: #919eab;
}
@media (min-width: 576px) {
  #create-event .list-group-item .icon-item {
    margin-right: 1rem;
  }
}
#create-event .list-group-item.active:hover {
  cursor: pointer;
}
#create-event .list-group-item.active .title {
  color: #212b36 !important;
}
#create-event .list-group-item.active .txt {
  color: #314154 !important;
}
#create-event .list-group-item.active .icon-item {
  background: #e9eeff !important;
  color: #0046c2 !important;
}
#create-event .list-group .icon-arrow_thick:before {
  position: absolute;
  top: calc(50% - 3px);
  right: 0;
  content: "\e907";
  font-size: 0.625rem;
}
#create-event .list-group .challenges:before {
  position: absolute;
  content: "\e103";
  font-size: 1.5rem;
}
#create-event .list-group .virtual-race:before {
  position: absolute;
  content: "\e107";
  font-size: 1.5rem;
}

#cancel-create-event, .uprace-popup--v2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*overflow-x: hidden;
  overflow-y: auto;*/
  outline: 0;
}
#cancel-create-event .popup-dialog, .uprace-popup--v2 .popup-dialog {
  position: relative !important;
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  margin: 1.5rem 0.5rem;
}
@media (min-width: 1024px) {
  #cancel-create-event .popup-dialog, .uprace-popup--v2 .popup-dialog {
    margin: 1.5rem auto 0 !important;
    max-width: 900px !important;
  }
}
#cancel-create-event .popup-content, .uprace-popup--v2 .popup-content {
  max-width: 500px;
  border-radius: 0.5rem !important;
}
#cancel-create-event .popup-header, .uprace-popup--v2 .popup-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
#cancel-create-event .popup-header__title, .uprace-popup--v2 .popup-header__title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #212b36;
}
#cancel-create-event .popup-body, .uprace-popup--v2 .popup-body {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 1.25rem;
}
#cancel-create-event .popup-body .icon-warning, .uprace-popup--v2 .popup-body .icon-warning {
  margin-right: 0.5rem;
}
#cancel-create-event .popup-body .icon-warning:before, .uprace-popup--v2 .popup-body .icon-warning:before {
  content: "\e112";
  color: #ffab00;
  font-size: 1.25rem;
}
#cancel-create-event .popup-body .icon-info, .uprace-popup--v2 .popup-body .icon-info {
  margin-right: 0.5rem;
}
#cancel-create-event .popup-body .icon-info:before, .uprace-popup--v2 .popup-body .icon-info:before {
  content: "\e91a";
  color: #0046c2;
  font-size: 1.25rem;
}
#cancel-create-event .popup-body .popup-title, .uprace-popup--v2 .popup-body .popup-title {
  color: #212b36;
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}
#cancel-create-event .popup-body .popup-text, .uprace-popup--v2 .popup-body .popup-text {
  color: #727f8e;
  font-size: 1rem;
  line-height: 1.5rem;
}
#cancel-create-event .popup-body .action, .uprace-popup--v2 .popup-body .action {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
#cancel-create-event .popup-body .action > *, .uprace-popup--v2 .popup-body .action > * {
  margin: 0.5rem;
}
#cancel-create-event .popup-body .action .btn, .uprace-popup--v2 .popup-body .action .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  height: 40px;
  border-radius: 0.5rem;
  min-width: 90px;
}
#cancel-create-event .popup-body .avatar-item, .uprace-popup--v2 .popup-body .avatar-item {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
#cancel-create-event .popup-body .txt, .uprace-popup--v2 .popup-body .txt {
  font-size: 1rem;
  color: #314154;
  margin-bottom: 1rem;
}
#cancel-create-event .popup-body .txt-title, .uprace-popup--v2 .popup-body .txt-title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #314154;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#cancel-create-event .popup-footer, .uprace-popup--v2 .popup-footer {
  display: flex;
  border-top: 1px solid #e7eaec;
  padding: 1rem;
}
#cancel-create-event .popup-footer .action, .uprace-popup--v2 .popup-footer .action {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
}
#cancel-create-event .popup-footer .action > *, .uprace-popup--v2 .popup-footer .action > * {
  margin: 0.5rem;
}
#cancel-create-event .popup-footer .action .btn, .uprace-popup--v2 .popup-footer .action .btn {
  border-radius: 0.5rem;
  min-width: 90px;
}
#cancel-create-event .form-control:focus, .uprace-popup--v2 .form-control:focus {
  border-color: #3E64FF;
  box-shadow: inset 0 1px 1px rgba(62, 100, 255, 0.075), 0 0 8px rgba(62, 100, 255, 0.6);
}
#cancel-create-event .img-fluid, .uprace-popup--v2 .img-fluid {
  max-width: 100%;
  height: auto;
}

.group-event-challenges-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*overflow-x: hidden;
  overflow-y: auto;*/
  outline: 0;
}
.group-event-challenges-popup .popup-dialog {
  position: relative !important;
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  margin: 1.5rem 0.5rem;
}
@media (min-width: 1024px) {
  .group-event-challenges-popup .popup-dialog {
    margin: 1.5rem auto 0 !important;
    max-width: 900px !important;
  }
}
.group-event-challenges-popup .popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 1.5rem);
  min-height: calc(100% - 1.5rem);
  overflow: hidden;
  border-radius: 0.25rem !important;
  outline: 0;
}
@media (min-width: 1024px) {
  .group-event-challenges-popup .popup-content {
    height: calc(100% - 6rem) !important;
    min-height: calc(100% - 6rem) !important;
  }
}
.group-event-challenges-popup .popup-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: auto;
}
.group-event-challenges-popup .popup-body .frm-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .group-event-challenges-popup .popup-body .frm-row {
    flex-direction: column;
  }
}
.group-event-challenges-popup .popup-body .frm-row__item {
  position: relative;
}
.group-event-challenges-popup .popup-body .frm-row__item.search {
  height: 40px;
}
.group-event-challenges-popup .popup-body .frm-row__item.search:before {
  position: absolute;
  left: 10px;
  top: calc(50% - 10px + 0px);
  font-size: 20px;
  content: "\e91f";
  color: #727f8e;
}
.group-event-challenges-popup .popup-body .frm-row__item.search > input {
  background: #f9fafb;
  width: 100%;
  height: 100%;
  padding: 15px 45px 15px 45px;
  color: #000;
  font-size: 16px;
  border: 1px solid #dbdfe2;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .group-event-challenges-popup .popup-body .frm-row__item.search > input {
    min-width: 400px;
  }
}
.group-event-challenges-popup .popup-body .list-group {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.group-event-challenges-popup .popup-body .list-group-item {
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (max-width: 1023px) {
  .group-event-challenges-popup .popup-body .list-group-item {
    width: 100%;
  }
}
.group-event-challenges-popup .popup-body .group-box {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #e7eaec;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  flex-direction: row;
}
@media (max-width: 768px) {
  .group-event-challenges-popup .popup-body .group-box {
    text-align: center;
    flex-direction: column;
  }
}
.group-event-challenges-popup .popup-body .group-logo__thumbnail {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.group-event-challenges-popup .popup-body .group-details {
  flex-grow: 1 !important;
  margin-left: 1rem;
}
@media (max-width: 768px) {
  .group-event-challenges-popup .popup-body .group-details {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.group-event-challenges-popup .popup-body .group-details__name {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #314154;
  margin-bottom: 0.5rem;
  max-height: 3rem;
}
.group-event-challenges-popup .popup-body .group-details__owner {
  font-weight: normal;
  font-size: 0.875rem;
  color: #727f8e;
  margin-bottom: 0.5rem;
}
.group-event-challenges-popup .popup-body .group-details__owner:before {
  content: "\e927";
  margin-right: 0.25rem;
}
.group-event-challenges-popup .popup-body .group-details__size {
  font-weight: normal;
  font-size: 0.875rem;
  color: #727f8e;
}
.group-event-challenges-popup .popup-body .group-details__size:before {
  content: "\e91e";
  margin-right: 0.25rem;
  font-size: 1rem;
}
.group-event-challenges-popup .popup-body .group-details__size .number {
  font-weight: 600;
  font-size: 14px;
  color: #314154;
}
.group-event-challenges-popup .popup-body .group-btn {
  display: flex;
  margin-left: 1rem;
}
@media (max-width: 768px) {
  .group-event-challenges-popup .popup-body .group-btn {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
}
.group-event-challenges-popup .popup-body .group-btn .btn-choose {
  height: 32px;
  padding: 0 0.5rem;
  min-width: 80px;
  line-height: 1.5rem;
  font-size: 1rem;
  width: max-content;
}
@media (max-width: 768px) {
  .group-event-challenges-popup .popup-body .group-btn .btn-choose {
    width: 100%;
  }
}
.group-event-challenges-popup .popup-body .group-create-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.group-event-challenges-popup .popup-body .group-create-box .thumbnail-item {
  width: 175px;
  height: 175px;
}
.group-event-challenges-popup .popup-body .group-create-box .txt {
  font-size: 1rem;
  color: #314154;
  margin-bottom: 1rem;
}
.group-event-challenges-popup .popup-body .group-create-box .txt-title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #314154;
  margin-bottom: 1rem;
}
.group-event-challenges-popup .popup-body .alert {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 0.625rem;
  font-size: 1rem;
}
.group-event-challenges-popup .popup-body .alert .icon {
  width: 40px;
  height: auto;
  margin-right: 0.5rem;
}
.group-event-challenges-popup .popup-body .alert-info {
  color: #314154;
  background: #e9eeff;
}
@media (max-width: 767px) {
  .group-event-challenges-popup .popup-body .btn-add {
    margin-top: 1rem;
    width: 100%;
  }
}
.group-event-challenges-popup .popup-body .btn-add:before {
  content: "\e100";
  margin-right: 5px;
}
.group-event-challenges-popup .popup-footer {
  display: flex;
  border-top: 1px solid #e7eaec;
  padding: 1rem;
}
.group-event-challenges-popup .popup-footer .btn-close {
  font-weight: 600;
  padding: 0.5rem 1.5rem;
}

.group__owner {
  font-weight: normal;
  font-size: 1rem;
  color: #727f8e;
  margin-top: 0.5rem;
}
.group__owner:before {
  content: "\e927";
  margin-right: 0.25rem;
}

.request-join-event .popup-body, .otp-verification .popup-body {
  padding: 2.5rem 2rem 1.5rem;
}
.request-join-event .request-join-img, .otp-verification .request-join-img {
  width: 160px;
}
.request-join-event .avatar-item, .otp-verification .avatar-item {
  width: 9rem !important;
  height: 9rem !important;
}
.request-join-event .row, .otp-verification .row {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.request-join-event .row > *, .otp-verification .row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.request-join-event .col, .otp-verification .col {
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}

/*# sourceMappingURL=popup.css.map */
